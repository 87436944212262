.ticket-table-dashboard {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  padding: 25px;
  margin-top: 20px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 255, 0.1);
}

.status-filter label {
  margin-right: 10px;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: var(--glow-cyan);
}

.status-filter select {
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
}

.status-filter select:focus {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
  outline: none;
}

.pagination {
  display: flex;
  gap: 5px;
}

.pagination button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pagination button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.pagination button.active {
  background: rgba(0, 255, 255, 0.15);
  border-color: var(--neon-cyan);
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  box-shadow: var(--glow-cyan);
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

th {
  text-align: left;
  padding: 16px;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  text-shadow: var(--glow-cyan);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

td {
  padding: 16px;
  background: rgba(13, 14, 26, 0.7);
  color: #fff;
  transition: all 0.3s ease;
  border-top: 1px solid rgba(0, 255, 157, 0.1);
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
}

tr:hover td {
  background: rgba(0, 255, 157, 0.05);
  box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.1);
  cursor: pointer;
}

th.sortable {
  cursor: pointer;
}

th.sortable:hover {
  color: var(--neon-green);
  text-shadow: var(--glow-green);
}

.channel-icon {
  margin-right: 8px;
  font-size: 18px;
  filter: drop-shadow(var(--glow-cyan));
}

.sentiment-score {
  padding: 4px 12px;
  border-radius: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.sentiment-score.low {
  background: rgba(255, 0, 0, 0.1);
  color: #ff0000;
  border: 1px solid rgba(255, 0, 0, 0.2);
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.4);
}

.sentiment-score.medium {
  background: rgba(255, 255, 0, 0.1);
  color: #ffff00;
  border: 1px solid rgba(255, 255, 0, 0.2);
  text-shadow: 0 0 10px rgba(255, 255, 0, 0.4);
}

.sentiment-score.high {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
  text-shadow: var(--glow-green);
}

.status-badge {
  padding: 4px 12px;
  border-radius: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status-badge.pending {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.2);
  text-shadow: var(--glow-cyan);
}

.status-badge.automated {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
  text-shadow: var(--glow-green);
}

.status-badge.waiting_carrier_response {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
  text-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
}

.status-badge.new {
  background: rgba(147, 112, 219, 0.1);
  color: #9370db;
  border: 1px solid rgba(147, 112, 219, 0.2);
  text-shadow: 0 0 10px rgba(147, 112, 219, 0.4);
}

.status-badge.affected {
  background: rgba(255, 215, 0, 0.1);
  color: #ffd700;
  border: 1px solid rgba(255, 215, 0, 0.2);
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.4);
}

.status-badge.waiting_client_response {
  background: rgba(255, 99, 71, 0.1);
  color: #d85df7;
  border: 1px solid rgba(255, 99, 71, 0.2);
  text-shadow: 0 0 10px rgba(255, 99, 71, 0.4);
}

@media (max-width: 768px) {
  .table-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .status-filter {
    margin-bottom: 10px;
  }

  table {
    font-size: 14px;
  }

  th, td {
    padding: 12px;
  }
}

.tags-container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.ticket-tag {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-magenta);
  border: 1px solid rgba(255, 0, 255, 0.2);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
}
.pagination span {
  color: var(--neon-cyan);
  padding: 8px 12px;
  background: rgba(0, 255, 255, 0.05);
  border: 1px solid var(--neon-cyan);
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tags-container {
  position: relative;
}

.tags-container:hover::after {
  content: attr(title);
  position: absolute;
  background: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}