.payment-result {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f8f9fa;
  }
  
  .payment-result-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
  }
  
  .payment-result h1 {
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .payment-result.success h1 {
    color: #28a745;
  }
  
  .payment-result.error h1 {
    color: #dc3545;
  }
  
  .payment-result p {
    margin-bottom: 1rem;
    color: #666;
  }
  
  .payment-result-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    margin-top: 1.5rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .payment-result-button:hover {
    background-color: #0056b3;
  }
  
  .payment-result.loading {
    color: #666;
    font-size: 1.2rem;
  }