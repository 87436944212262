/* Overall page background */
.live-buy-wrapper {
    min-height: 100vh;
    width: 100%;
    background: #ffffff;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  /* Main container card */
  .live-buy-container {
    width: 100%;
    max-width: 800px;
    background: #ffffff;
    border-radius: 12px;
    padding: 2.5rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
  }
  
  /* Header styling */
  .live-buy-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .live-buy-header h1 {
    font-size: 2.2rem;
    color: #111;
    margin-bottom: 0.75rem;
    font-weight: 600;
  }
  
  .live-buy-header p {
    color: #666;
    font-size: 0.95rem;
  }
  
  /* Step indicator */
  .live-buy-step-indicator {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2.5rem;
  }
  
  .live-buy-step-indicator span {
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    background: #f5f5f5;
    transition: all 0.3s ease;
    font-weight: 500;
  }
  
  .live-buy-step-indicator span.active {
    background: #000000;
    color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Search container */
  .live-buy-search-container {
    position: relative;
    margin-bottom: 2rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  .live-buy-search-wrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .live-buy-search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 1rem;
  }
  
  .live-buy-search-input {
    width: 100%;
    height: 3.5rem;
    padding: 0 1rem 0 3rem;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .live-buy-search-input:focus {
    border-color: #000000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  /* Search results dropdown */
  .live-buy-search-results {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .live-buy-search-item {
    display: flex;
    align-items: center;
    padding: 0.8rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    transition: background 0.3s;
  }
  
  .live-buy-search-item:last-child {
    border-bottom: none;
  }
  
  .live-buy-search-item:hover {
    background: #f8f9fa;
  }
  
  .live-buy-search-item-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 0.8rem;
  }
  
  .live-buy-search-item-info {
    display: flex;
    flex-direction: column;
  }
  
  .live-buy-search-item-title {
    font-size: 0.95rem;
    color: #333;
    margin-bottom: 0.2rem;
  }
  
  .live-buy-search-item-price {
    font-size: 0.85rem;
    color: #777;
  }
  
  /* Selected products */
  .live-buy-selected-products {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .live-buy-product {
    background: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 1rem;
  }
  
  .live-buy-product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .live-buy-product-info {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  
  .live-buy-product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .live-buy-product-title {
    font-size: 1.1rem;
    color: #333;
    margin: 0;
  }
  
  .live-buy-remove {
    background: transparent;
    border: none;
    color: #d9534f;
    font-size: 1.4rem;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .live-buy-remove:hover {
    color: #c9302c;
  }
  
  /* Variants & quantity */
  .live-buy-variants {
    margin-top: 1rem;
  }
  
  .live-buy-variant-colors {
    margin-bottom: 1rem;
  }
  
  .live-buy-variant-sizes {
    margin-top: 0.5rem;
  }
  
  .live-buy-variant-button {
    padding: 0.75rem 1.25rem;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    background: #ffffff;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .live-buy-variant-button:hover:not(:disabled) {
    border-color: #000000;
    background: #f8f8f8;
  }
  
  .live-buy-variant-button.selected {
    background: #000000;
    color: #ffffff;
    border-color: #000000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .live-buy-variant-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .variant-color {
    font-weight: 600;
  }
  
  .variant-size {
    font-size: 0.75rem;
    margin-top: 2px;
  }
  
  /* Quantity controls */
  .live-buy-quantity {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.8rem;
  }
  
  .live-buy-quantity button {
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background 0.3s, border-color 0.3s;
  }
  
  .live-buy-quantity button:hover {
    border-color: #4a90e2;
    background: #f0f8ff;
  }
  
  .live-buy-quantity span {
    min-width: 2rem;
    text-align: center;
    font-size: 1rem;
  }
  
  /* Customer shipping form */
  .live-buy-customer {
    margin-top: 1rem;
    padding: 0 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .live-buy-customer-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .live-buy-form-group {
    margin-bottom: 1.5rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .live-buy-label {
    display: block;
    margin-bottom: 0.4rem;
    font-size: 0.9rem;
    color: #555;
  }
  
  .live-buy-input {
    width: 100%;
    height: 3.2rem;
    padding: 0 1rem;
    border: 2px solid #eaeaea;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
  }
  
  .live-buy-input:focus {
    border-color: #000000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  /* Footer / summary section */
  .live-buy-footer {
    margin-top: 2rem;
    padding: 1.5rem 0;
    border-top: 1px solid #e0e0e0;
  }
  
  .live-buy-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .live-buy-shipping {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #555;
    font-size: 0.9rem;
  }
  
  .live-buy-total-amount {
    font-weight: 600;
    font-size: 1rem;
    color: #333;
  }
  
  /* Action buttons */
  .live-buy-actions {
    display: flex;
    gap: 1rem;
  }
  
  .live-buy-button {
    height: 3.2rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0 2rem;
  }
  
  .live-buy-button-primary {
    background: #000000;
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .live-buy-button-primary:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .live-buy-button-secondary {
    background: #ffffff;
    color: #000000;
    border: 2px solid #eaeaea;
  }
  
  .live-buy-button-secondary:hover:not(:disabled) {
    background: #f8f8f8;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  /* Loading and error messages */
  .live-buy-loading,
  .live-buy-error {
    text-align: center;
    font-size: 1.2rem;
    color: #333;
    padding: 2rem;
  }
  
  .live-buy-payment {
    position: relative;
  }
  
  .live-buy-payment-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .live-buy-payment-message {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .live-buy-payment-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
  }
  
  .live-buy-payment-icon.success {
    border: 3px solid #4CAF50;
    background-color: rgba(76, 175, 80, 0.1);
  }
  
  .live-buy-payment-icon.error {
    border: 3px solid #f44336;
    background-color: rgba(244, 67, 54, 0.1);
  }
  
  .live-buy-payment-icon svg {
    font-size: 40px;
  }
  
  .live-buy-payment-icon.success svg {
    color: #4CAF50;
  }
  
  .live-buy-payment-icon.error svg {
    color: #f44336;
  }
  
  .live-buy-payment-message p {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
  }
  
  .live-buy-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    padding: 2rem;
  }
  
  .live-buy-error-title {
    font-size: 1.5rem;
    color: #666;
    text-align: center;
  }
  
  .live-buy-delivery-options {
    margin-bottom: 2rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    display: flex;
    gap: 2rem;
  }
  
  .live-buy-radio-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .live-buy-radio-label:hover {
    background-color: #eee;
  }
  
  .live-buy-radio-label input[type="radio"] {
    margin: 0;
    width: 1.2rem;
    height: 1.2rem;
  }
  
  .live-buy-radio-label span {
    font-size: 1rem;
    color: #333;
  }
  