/* Outer container taking the full screen */
.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #000000, #2c003e);
  }
  
  /* Inner container for the actual reset password form */
  .reset-password-container {
    max-width: 400px;
    padding: 20px;
    background-color: #333333;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .reset-password-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .reset-password-header .logo {
    max-width: 80%;
    height: auto;
  }
  
  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .reset-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-container input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #555555;
    border-radius: 4px;
    background-color: #222222;
    color: #ffffff;
  }
  
  .reset-password-container input:focus {
    outline: none;
    border-color: #888888;
  }
  
  .reset-password-container button {
    padding: 10px;
    background-color: #555555;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reset-password-container button:hover {
    background-color: #777777;
  }
  
  .reset-password-container p {
    text-align: center;
    margin-top: 20px;
    color: #ffdddd;
  }
  