/* src/components/CreateForm.css */

.create-form-dashboard {
  padding: 30px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 30px;
  position: relative;
  z-index: 1;
}

/* Panel Styling */
.forms-panel,
.creation-panel {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 20px;
  padding: 25px;
  backdrop-filter: blur(10px);
  box-shadow: var(--glow-cyan);
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.panel-title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--neon-cyan);
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 25px;
  text-shadow: var(--glow-cyan);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  padding-bottom: 15px;
}

.panel-icon {
  font-size: 1.2em;
  filter: drop-shadow(0 0 5px var(--neon-cyan));
}

/* Form Cards */
.form-card {
  background: rgba(26, 26, 46, 0.6);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 15px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}

.form-card:hover {
  transform: translateY(-3px);
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.form-card-content {
  padding: 20px;
  position: relative;
}

.form-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.form-icon {
  color: var(--neon-cyan);
  font-size: 24px;
  filter: drop-shadow(0 0 5px var(--neon-cyan));
}

.form-card-stats {
  display: flex;
  gap: 20px;
}

.stat {
  flex: 1;
  text-align: center;
  padding: 10px;
  background: rgba(0, 255, 255, 0.1);
  border-radius: 8px;
}

.stat-value {
  display: block;
  font-size: 1.2em;
  color: var(--neon-green);
  text-shadow: var(--glow-green);
  font-weight: 600;
}

.stat-label {
  font-size: 0.7em;
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;
}

/* Creation Form */
.creation-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-card {
  background: rgba(26, 26, 46, 0.6);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease;
}

.question-card:hover {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.question-number {
  color: var(--neon-cyan);
  font-size: 1.2em;
  font-weight: 600;
  text-shadow: var(--glow-cyan);
}

.remove-question {
  background: transparent;
  border: none;
  color: var(--neon-magenta);
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.6;
}

.remove-question:hover {
  opacity: 1;
  transform: scale(1.1);
  text-shadow: var(--glow-magenta);
}

.question-fields {
  display: grid;
  gap: 15px;
}

/* Form Actions */
.form-actions {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.add-question-button,
.submit-button {
  padding: 12px 24px;
  border: 1px solid var(--neon-cyan);
  background: transparent;
  color: var(--neon-cyan);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
}

.add-question-button:hover,
.submit-button:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.submit-button {
  border-color: var(--neon-green);
  color: var(--neon-green);
}

.submit-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
}

/* Messages */
.error-message,
.success-message {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  animation: messageSlide 0.3s ease;
}

.error-message {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid var(--neon-magenta);
  color: var(--neon-magenta);
}

.success-message {
  background: rgba(0, 255, 157, 0.1);
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
}

@keyframes messageSlide {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
  
  .forms-panel,
  .creation-panel {
    height: auto;
    max-height: 600px;
  }
}
