.wms-chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 20px;
    box-shadow: var(--glow-cyan);
    backdrop-filter: blur(10px);
    overflow: hidden;
    position: relative;
    height: 100%;
}

.wms-chat-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        linear-gradient(transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%),
        linear-gradient(90deg, transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%);
    background-size: 30px 30px;
    opacity: 0.05;
    pointer-events: none;
}

.wms-chat-messages {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    min-height: 0;
}

.wms-chat-message {
    max-width: 85%;
    padding: 15px;
    border-radius: 12px;
    margin: 4px 0;
    line-height: 1.6;
    font-size: 14px;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    animation: messageAppear 0.3s ease;
}

.wms-chat-message.user {
    background: rgba(98, 0, 234, 0.1);
    border: 1px solid var(--neon-purple);
    box-shadow: var(--glow-purple);
    align-self: flex-end;
    color: var(--text-color);
}

.wms-chat-message.bot {
    background: rgba(26, 26, 46, 0.6);
    border: 1px solid var(--neon-cyan);
    box-shadow: var(--glow-cyan);
    align-self: flex-start;
    color: var(--text-color);
}

.wms-chat-input {
    flex: 0 0 auto;
    padding: 20px;
    border-top: 1px solid var(--neon-cyan);
    display: flex;
    gap: 12px;
    background: rgba(26, 26, 46, 0.9);
    backdrop-filter: blur(10px);
    position: relative;
    width: 100%;
    bottom: 0;
    box-sizing: border-box;
}

.wms-chat-input form {
    width: 100%;
    display: flex;
    gap: 12px;
}

.wms-chat-input input {
    flex: 1;
    padding: 12px 20px;
    background: rgba(26, 26, 46, 0.8);
    border: 1px solid var(--neon-cyan);
    border-radius: 12px;
    outline: none;
    color: var(--text-color);
    font-size: 14px;
    transition: all 0.3s ease;
    box-shadow: var(--glow-cyan);
    min-width: 0;
    width: 100%;
    box-sizing: border-box;
}

.wms-chat-input input:focus {
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan), 0 0 20px rgba(0, 255, 255, 0.2);
    background: rgba(26, 26, 46, 0.9);
}

.wms-chat-send-button {
    padding: 12px 24px !important;
    height: auto !important;
    border-radius: 12px !important;
    border: 1px solid var(--neon-green) !important;
    background: rgba(26, 26, 46, 0.8) !important;
    color: var(--neon-green) !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    cursor: pointer !important;
    font-weight: 500 !important;
    transition: all 0.3s ease !important;
    white-space: nowrap !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    backdrop-filter: blur(5px) !important;
}

.wms-chat-send-button:hover {
    background: rgba(98, 0, 234, 0.1) !important;
    box-shadow: var(--glow-purple) !important;
    transform: translateY(-2px) !important;
}

/* Scrollbar Styling */
.wms-chat-messages::-webkit-scrollbar {
    width: 6px;
}

.wms-chat-messages::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.4);
}

.wms-chat-messages::-webkit-scrollbar-thumb {
    background: var(--neon-cyan);
    border-radius: 3px;
    box-shadow: var(--glow-cyan);
}

.wms-chat-messages::-webkit-scrollbar-thumb:hover {
    background: var(--neon-purple);
    box-shadow: var(--glow-purple);
}

@keyframes messageAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}