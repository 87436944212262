.una-product-details-container {
    padding: 25px;
    margin: 20px;
    max-width: 1400px;
    margin: 20px auto;
  }
  
  .una-back-button {
    background: transparent;
    border: 1px solid var(--neon-cyan);
    color: var(--neon-cyan);
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .una-back-button:hover {
    background: rgba(0, 255, 255, 0.1);
    box-shadow: var(--glow-cyan);
    transform: translateY(-2px);
  }
  
  .una-product-title {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 1em;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-product-grid {
    display: grid;
    grid-template-columns: minmax(400px, 1fr) 2fr;
    gap: 40px;
    align-items: start;
  }
  
  .una-product-image-container {
    position: sticky;
    top: 20px;
  }
  
  .una-product-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.2);
    box-shadow: var(--glow-green);
  }
  
  .una-product-info {
    display: grid;
    gap: 25px;
  }
  
  .una-product-meta {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    background: rgba(13, 14, 26, 0.7);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-meta-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .una-meta-label {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-meta-value {
    color: #fff;
    font-size: 1.1em;
  }
  
  .una-variants-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }
  
  .una-variant-card {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    padding: 15px;
    transition: all 0.3s ease;
    color: #fff;
  }
  
  .una-variant-card p {
    color: rgba(255, 255, 255, 0.9);
    margin: 5px 0;
  }
  
  .una-options-section {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    padding: 20px;
  }
  
  .una-options-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .una-option-group {
    margin-bottom: 15px;
  }
  
  .una-option-group h4 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    margin-bottom: 10px;
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-option-values {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  
  .una-loading, .una-error {
    text-align: center;
    padding: 40px;
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    background: rgba(0, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 255, 0.1);
    margin: 20px;
  }
  
  .una-error {
    color: var(--neon-magenta);
    text-shadow: var(--glow-magenta);
    background: rgba(255, 0, 255, 0.05);
    border: 1px solid rgba(255, 0, 255, 0.1);
  }
  
  @media (max-width: 1024px) {
    .una-product-grid {
      grid-template-columns: 1fr;
    }

    .una-product-image-container {
      position: relative;
      top: 0;
      max-width: 600px;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 768px) {
    .una-variants-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .una-product-description {
    background: rgba(13, 14, 26, 0.7);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    margin: 15px 0;
    color: #fff;
  }
  
  .una-product-description h3 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-product-description p,
  .una-product-description li,
  .una-product-description span {
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
  }
  
  .una-option-value {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-cyan);
    border: 1px solid rgba(0, 255, 255, 0.2);
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-shadow: var(--glow-cyan);
  }
  
  .una-variant-price {
    color: var(--neon-green) !important;
    text-shadow: var(--glow-green);
    font-weight: 500;
  }