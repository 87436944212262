/* ChatbotPreview.css */
.chatbot-preview-container {
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-header {
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chatbot-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .chatbot-messages {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #f9f9f9;
  }
  
  .chatbot-message {
    max-width: 80%;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 15px;
    position: relative;
  }
  
  .chatbot-message.bot {
    align-self: flex-start;
  }
  
  .chatbot-message.user {
    align-self: flex-end;
    background-color: #007bff;
    color: #fff;
  }
  
  .chatbot-message-sender {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .chatbot-input-container {
    display: flex;
    padding: 15px;
    border-top: 1px solid #eee;
    background-color: #fff;
  }
  
  .chatbot-input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    resize: none;
    outline: none;
    font-family: inherit;
  }
  
  .chatbot-send-button {
    margin-left: 10px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
  }