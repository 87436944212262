:root {
  --primary-color: #00ff9d;
  --primary-light: #00ffff;
  --primary-dark: #1a1a2e;
  --secondary-color: #ff00ff;
  --secondary-light: #ff60ff;
  --secondary-dark: #b100b1;
  --background-color: #1a1a2e;
  --text-color: #ffffff;
  --border-color: rgba(0, 255, 157, 0.2);
  --gradient-bg: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  --gradient-card: linear-gradient(180deg, rgba(26, 26, 46, 0.9), rgba(22, 33, 62, 0.9));
  --glow-green: 0 0 20px rgba(0, 255, 157, 0.3);
  --glow-cyan: 0 0 20px rgba(0, 255, 255, 0.3);
  --glow-magenta: 0 0 20px rgba(255, 0, 255, 0.3);
}

.una-fast-live-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: var(--gradient-bg);
  min-height: 100vh;
}

.una-live-form {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 20px;
  box-shadow: var(--glow-green);
  border: 1px solid var(--border-color);
}

.una-form-header {
  margin-bottom: 25px;
}

.una-form-header h2 {
  color: var(--primary-color);
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-green);
  display: flex;
  align-items: center;
  gap: 10px;
}

.una-form-group {
  margin-bottom: 20px;
}

.una-input, .una-textarea, .una-select {
  width: 100%;
  padding: 12px;
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  font-size: 14px;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.una-textarea {
  min-height: 100px;
  resize: vertical;
}

.una-input:focus, .una-textarea:focus, .una-select:focus {
  border-color: var(--primary-color);
  box-shadow: var(--glow-green);
  outline: none;
  background: rgba(0, 255, 157, 0.05);
}

.una-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.una-input-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.una-input-icon svg {
  position: absolute;
  left: 12px;
  color: var(--primary-color);
  font-size: 18px;
}

.una-input-icon input {
  padding-left: 40px;
}

.una-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

.una-product-card {
  background: rgba(26, 26, 46, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  position: relative;
}

.una-product-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--glow-cyan);
  border-color: var(--primary-light);
}

.una-product-card.selected {
  border-color: var(--secondary-color);
  box-shadow: var(--glow-magenta);
}

.una-product-card.selected::before {
  content: '✓';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--background-color);
  font-size: 14px;
  box-shadow: var(--glow-magenta);
  z-index: 1;
}

.una-product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid var(--border-color);
}

.una-product-info {
  padding: 15px;
}

.una-product-info h3 {
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 8px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.una-product-info p {
  color: var(--primary-light);
  font-size: 14px;
  opacity: 0.8;
}

.una-submit-button {
  width: 100%;
  padding: 15px;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-green);
}

.una-submit-button:hover:not(:disabled) {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.una-submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: var(--border-color);
}

@media (max-width: 768px) {
  .una-form-row {
    grid-template-columns: 1fr;
  }
  
  .una-products-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.una-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-color);
  cursor: pointer;
  padding: 10px;
  margin-bottom: 20px;
  width: fit-content;
  transition: all 0.3s ease;
  font-size: 16px;
}

.una-back-button:hover {
  color: var(--primary-light);
  transform: translateX(-5px);
}
