:root {
    --primary-color: #00ff9d;
    --primary-light: #00ffff;
    --primary-dark: #1a1a2e;
    --secondary-color: #ff00ff;
    --secondary-light: #ff60ff;
    --secondary-dark: #b100b1;
    --background-color: #1a1a2e;
    --text-color: #ffffff;
    --border-color: rgba(0, 255, 157, 0.2);
    --gradient-bg: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    --gradient-card: linear-gradient(180deg, rgba(26, 26, 46, 0.9) 0%, rgba(22, 33, 62, 0.9) 100%);
    --glow-green: 0 0 20px rgba(0, 255, 157, 0.3);
    --glow-cyan: 0 0 20px rgba(0, 255, 255, 0.3);
    --glow-magenta: 0 0 20px rgba(255, 0, 255, 0.3);
  }
  
  .chatbot-test-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .messages-container {
    flex: 1;
    padding: 25px;
    background: var(--gradient-card);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid var(--border-color);
    box-shadow: var(--glow-green);
    margin-bottom: 20px;
    overflow-y: auto;
    max-height: 70vh;
  }
  
  .message {
    margin: 15px 0;
    padding: 15px;
    border-radius: 12px;
    max-width: 70%;
    animation: messageAppear 0.3s ease;
  }
  
  .message.user {
    margin-left: auto;
    background: rgba(0, 255, 157, 0.1);
    border: 1px solid var(--primary-color);
    box-shadow: var(--glow-green);
    color: var(--text-color);
  }
  
  .message.ai {
    margin-right: auto;
    background: rgba(0, 255, 255, 0.1);
    border: 1px solid var(--primary-light);
    box-shadow: var(--glow-cyan);
    color: var(--text-color);
  }
  
  .message-content {
    margin-bottom: 5px;
    line-height: 1.5;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  
  .message-timestamp {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .message-form {
    padding: 20px;
    background: var(--gradient-card);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid var(--border-color);
    box-shadow: var(--glow-cyan);
  }
  
  .input-container-chatbottest {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .message-form input {
    flex: 1;
    padding: 16px 20px;
    padding-right: 50px;
    background: rgba(26, 26, 46, 0.7);
    border: 1px solid var(--primary-light);
    border-radius: 12px;
    color: var(--text-color);
    font-size: 16px;
    transition: all 0.3s ease;
    margin: 10px 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  
  .message-form input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
  }
  
  .send-button {
    position: absolute;
    right: 12px;
    background: transparent;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .send-button:hover {
    color: var(--primary-light);
    transform: translateX(2px);
  }
  
  .send-button:disabled {
    color: var(--secondary-color);
    cursor: not-allowed;
  }
  
  .send-button svg {
    width: 20px;
    height: 20px;
  }
  
  .send-button svg.loading {
    animation: rotate 1s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes messageAppear {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Custom Scrollbar */
  .messages-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .messages-container::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.7);
  }
  
  .messages-container::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 4px;
  }
  
  .messages-container::-webkit-scrollbar-thumb:hover {
    background: var(--primary-light);
    box-shadow: var(--glow-green);
  }
  
  .delete-conversation-btn {
    padding: 12px 24px;
    border: 1px solid var(--secondary-color);
    border-radius: 12px;
    background: transparent;
    color: var(--secondary-color);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .delete-conversation-btn:hover {
    background: rgba(255, 0, 255, 0.1);
    box-shadow: var(--glow-magenta);
    transform: translateY(-2px);
  }
  
  .delete-conversation-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
  }
  
  .chatbot-header-text {
    flex: 1;
  }
  
  .chatbot-intro {
    margin-bottom: 8px;
    color: #666;
    font-size: 0.9em;
  }