/* UserStatisticsPanel.css */

.user-statistics-panel {
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  padding: 30px;
  color: #fff;
  min-height: 100vh;
}

.panel-title {
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
}

.panel-title .icon {
  color: #00ff9d;
  filter: drop-shadow(0 0 8px rgba(0, 255, 157, 0.4));
}

.period-selector {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  padding: 5px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.period-selector button {
  padding: 12px 24px;
  background: transparent;
  color: #fff;
  border: 1px solid rgba(0, 255, 157, 0.3);
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.period-selector button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
}

.period-selector button:active {
  transform: translateY(1px);
}

.period-selector button.active {
  background: #00ff9d;
  border: none;
  color: #1a1a2e;
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.3);
}

.charts-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.line-charts {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.sentiment-statistics-section,
.resolved-statistics-section,
.tags-statistics-section {
  background: rgba(13, 14, 26, 0.95);
  border-radius: 20px;
  padding: 25px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  backdrop-filter: blur(10px);
}

.chart-container {
  position: relative;
  height: 400px;
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.02);
}

h3 {
  font-size: 1.4em;
  margin-bottom: 20px;
  color: #00ffff;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
}

.period-selector button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #8000ff, 0 0 0 4px rgba(128, 0, 255, 0.3);
}

.loading {
  color: #00ff9d;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.4);
}

.error-message {
  color: #ff00ff;
  background: rgba(255, 0, 255, 0.1);
  border-left: 4px solid #ff00ff;
  padding: 12px 20px;
  margin: 20px 0;
  border-radius: 0 4px 4px 0;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.4);
}

.una-sentiment-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-loading-content {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

.una-loading-icons {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.una-loading-icons svg {
  position: absolute;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 30px;
  color: var(--neon-cyan);
  animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green);
  animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta);
  animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.una-loading-progress {
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  border-radius: 2px;
  animation: progress 3s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
  animation: glow 1.5s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-metric-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes floatIcon {
  0%, 100% { 
    transform: translateY(0) scale(1); 
    opacity: 1;
  }
  50% { 
    transform: translateY(-10px) scale(1.1); 
    opacity: 0.8;
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}