.una-return-container {
  min-height: 100vh;
  background: var(--gradient-bg);
  padding: 20px;
}

.una-return-panels {
  display: flex;
  gap: 20px;
  height: calc(100vh - 40px);
}

.una-return-left-panel {
  width: 400px;
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 20px;
  box-shadow: var(--glow-green);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

.una-return-right-panel {
  flex: 1;
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 20px;
  box-shadow: var(--glow-green);
  border: 1px solid var(--border-color);
  overflow-y: auto;
}

.una-return-nav {
  margin-bottom: 20px;
}

.una-return-back-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: none;
  color: var(--neon-cyan);
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.una-return-back-btn:hover {
  transform: translateX(-4px);
  text-shadow: var(--glow-cyan);
}

.una-return-header {
  margin-bottom: 20px;
}

.una-return-title h1 {
  color: var(--neon-cyan);
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
  margin-bottom: 10px;
}

.una-return-meta {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.una-return-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
}

.una-status-badge {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  width: fit-content;
}

/* Keep existing status styles */

.una-return-section {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.una-return-section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--neon-cyan);
  margin-bottom: 1.5rem;
}

.una-return-section-header h2 {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-cyan);
}

.una-return-items-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

/* Keep existing item card styles */

.una-return-tracking-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.una-return-label-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: rgba(0, 255, 157, 0.1);
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 8px;
  color: var(--neon-green);
  text-decoration: none;
  transition: all 0.3s ease;
  width: fit-content;
}

.una-return-label-btn:hover {
  background: rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.2);
}

/* Add responsive design */
@media (max-width: 1024px) {
  .una-return-panels {
    flex-direction: column;
    height: auto;
  }

  .una-return-left-panel {
    width: 100%;
  }

  .una-return-right-panel {
    width: 100%;
  }
}

/* Keep existing styles for items, status badges, etc. */

.una-return-text {
  color: var(--text-color);
}

.una-return-item-card {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
}

.una-return-item-card:hover {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.una-return-item-card h3 {
  font-size: 1.1rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-return-item-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
}

.una-return-item-stat {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat-label {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--neon-cyan);
}

.stat-value {
  font-size: 1rem;
  font-weight: 500;
}

.una-return-tracking-number {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 8px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.tracking-label {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--neon-cyan);
}

.tracking-value {
  font-size: 1rem;
  font-weight: 500;
}

/* Refund UI Styles */
.una-items-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.una-refund-button {
  background: transparent;
  border: 1px solid var(--neon-magenta);
  color: var(--neon-magenta);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
}

.una-refund-button:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
}

.una-refund-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.una-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
}

.una-item:last-child {
  border-bottom: none;
}

.una-item-checkbox {
  margin-right: 15px;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid var(--neon-cyan);
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.una-item-checkbox:checked {
  background: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.una-item-checkbox:checked::after {
  content: '✓';
  position: absolute;
  color: var(--background-color);
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.una-item-quantity {
  width: 80px;
  padding: 8px;
  margin-top: 10px;
  background: rgba(13, 14, 26, 0.7);
  border: 1px solid var(--neon-cyan);
  border-radius: 6px;
  color: var(--text-color);
  font-size: 0.9em;
  transition: all 0.3s ease;
}

.una-item-quantity:focus {
  outline: none;
  box-shadow: var(--glow-cyan);
}

/* Modal Styles */
.una-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(13, 14, 26, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.una-modal {
  background: var(--gradient-card);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 25px;
  width: 90%;
  max-width: 500px;
  box-shadow: var(--glow-green);
}

.una-modal h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.una-modal-content {
  margin: 20px 0;
}

.una-modal textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  background: rgba(13, 14, 26, 0.7);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--text-color);
  margin-bottom: 15px;
  resize: vertical;
}

.una-modal textarea:focus {
  outline: none;
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.una-modal label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-color);
}

.una-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
}

.una-modal-actions button {
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
}

.una-modal-actions button:first-child {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
}

.una-modal-actions button:first-child:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
}

.una-modal-actions button:last-child {
  background: transparent;
  border: 1px solid var(--neon-magenta);
  color: var(--neon-magenta);
}

.una-modal-actions button:last-child:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
}

.una-error {
  color: #ff3e3e;
  background: rgba(255, 62, 62, 0.1);
  border: 1px solid #ff3e3e;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.una-tracking-details {
  margin-top: 1.5rem;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 20px;
}

.una-tracking-status {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
}

.tracking-status-label {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--neon-cyan);
  margin-right: 0.5rem;
}

.tracking-status-value {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-color);
  text-shadow: var(--glow-cyan);
}

.una-tracking-timeline {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}

.una-tracking-timeline::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, var(--neon-cyan), transparent);
}

.una-tracking-event {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.5rem;
  padding: 1rem 1rem 1rem 3rem;
  background: rgba(13, 14, 26, 0.7);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.una-tracking-event::before {
  content: '';
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background: var(--neon-cyan);
  border-radius: 50%;
  box-shadow: var(--glow-cyan);
}

.una-tracking-event:hover {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  transform: translateX(4px);
}

.event-date {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--neon-cyan);
  white-space: nowrap;
}

.event-location {
  color: var(--text-color);
  font-size: 0.9rem;
}

.event-status {
  text-align: right;
  font-weight: 500;
  color: var(--neon-green);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  white-space: nowrap;
}

.una-tracking-loading {
  margin-top: 1rem;
  text-align: center;
  color: var(--neon-cyan);
  padding: 1rem;
  background: rgba(13, 14, 26, 0.7);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

.una-return-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-loading-content {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

.una-loading-icons {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.una-loading-icons svg {
  position: absolute;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 30px;
  color: var(--neon-cyan);
  animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green);
  animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta);
  animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.una-loading-progress {
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  border-radius: 2px;
  animation: progress 3s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
  animation: glow 1.5s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-metric-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes floatIcon {
  0%, 100% { 
    transform: translateY(0) scale(1); 
    opacity: 1;
  }
  50% { 
    transform: translateY(-10px) scale(1.1); 
    opacity: 0.8;
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}