/* ChatbotSettings.css */

.chatbot-settings-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: rgba(26, 26, 46, 0.8);
  border-radius: 20px;
  border: 1px solid var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
}

/* Cyberpunk Grid Effect */
.chatbot-settings-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%),
    linear-gradient(90deg, transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%);
  background-size: 30px 30px;
  opacity: 0.05;
  pointer-events: none;
}

.settings-title {
  margin-bottom: 30px;
  color: var(--neon-cyan);
  text-align: center;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: var(--glow-cyan);
}

.chatbot-settings-flex {
  display: flex;
  gap: 40px;
  position: relative;
}

.chatbot-settings-form {
  flex: 1;
  background: rgba(26, 26, 46, 0.6);
  padding: 30px;
  border-radius: 15px;
  border: 1px solid var(--neon-magenta);
  box-shadow: var(--glow-magenta);
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: inline-block;
  min-width: 150px;
  margin-bottom: 10px;
  color: var(--neon-magenta);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-magenta);
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 12px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: var(--glow-cyan);
  box-sizing: border-box;
  resize: vertical;
  min-height: 100px;
  max-width: 100%;
}

.form-group input[type="text"]:focus,
.form-group textarea:focus {
  border-color: var(--neon-cyan);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
  outline: none;
}

.color-group {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
}

.color-group label {
  flex: 0 0 150px;
  margin-bottom: 0;
}

.color-group input[type="color"] {
  -webkit-appearance: none;
  width: 80px;
  height: 40px;
  border: 1px solid var(--neon-magenta);
  border-radius: 8px;
  background: rgba(26, 26, 46, 0.8);
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 2px;
  position: relative;
  overflow: hidden;
  margin-left: 0;
}

.color-group input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-group input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
  padding: 0;
}

.color-group input[type="color"]:hover {
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

.color-group input[type="color"]::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, 
    var(--neon-cyan),
    var(--neon-magenta),
    var(--neon-purple),
    var(--neon-cyan)
  );
  border-radius: 10px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.color-group input[type="color"]:hover::before {
  opacity: 1;
}

.color-group::after {
  content: attr(data-color);
  position: absolute;
  right: -80px;
  font-size: 12px;
  color: var(--text-color);
  opacity: 0.7;
  text-transform: uppercase;
}

.save-button {
  padding: 12px 30px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  border-radius: 12px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.save-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.save-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: 0.5s;
}

.save-button:hover::before {
  left: 100%;
}

.embed-code-section {
  margin-top: 30px;
  padding: 20px;
  background: rgba(26, 26, 46, 0.6);
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  box-shadow: var(--glow-cyan);
}

.embed-code-section h3 {
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
  margin-bottom: 15px;
}

.embed-code-textarea {
  width: 100%;
  height: 100px;
  padding: 15px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-magenta);
  border-radius: 8px;
  color: var(--text-color);
  font-family: monospace;
  resize: vertical;
  box-shadow: var(--glow-magenta);
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: auto;
}

.copy-embed-button {
  margin-top: 15px;
  padding: 10px 20px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-magenta);
  color: var(--neon-magenta);
  border-radius: 8px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy-embed-button:hover {
  background: rgba(255, 0, 255, 0.1);
  box-shadow: var(--glow-magenta);
  transform: translateY(-2px);
}

.chatbot-preview-section {
  flex: 1;
  background: rgba(26, 26, 46, 0.6);
  padding: 30px;
  border-radius: 15px;
  border: 1px solid var(--neon-magenta);
  box-shadow: var(--glow-magenta);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-title {
  color: var(--neon-magenta);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-magenta);
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .chatbot-settings-flex {
    flex-direction: column;
  }
  
  .chatbot-preview-section {
    margin-top: 40px;
  }
}
