:root {
  --message-sent: linear-gradient(135deg, rgba(98, 0, 234, 0.1), rgba(98, 0, 234, 0.2));
  --message-received: linear-gradient(135deg, rgba(26, 26, 46, 0.7), rgba(26, 26, 46, 0.8));
  --message-automated: linear-gradient(135deg, rgba(255, 152, 0, 0.1), rgba(255, 152, 0, 0.2));
}

.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--gradient-bg);
  padding: 20px;
  gap: 20px;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

.message-window-layout {
  flex: 1 1 70% !important;
  display: flex;
  flex-direction: column;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 20px;
  overflow: hidden;
  min-width: 0;
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(10px);
  max-width: 70% !important;
}

.message-window-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  position: relative;
}

.message-window-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%),
    linear-gradient(90deg, transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%);
  background-size: 30px 30px;
  opacity: 0.05;
  pointer-events: none;
}

.message-window-messages {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px;
  min-height: 0;
}

.message-window-messages .message {
  margin: 15px 0;
  padding: 15px;
  border-radius: 12px;
  max-width: 60% !important;
  width: 60% !important;
  word-break: break-word;
  overflow-wrap: break-word;
  position: relative;
  animation: messageAppear 0.3s ease;
  color: var(--text-color);
}

.message-window-messages .message.sent {
  background: var(--message-sent);
  margin-left: auto;
  border: 1px solid rgba(98, 0, 234, 0.3);
  box-shadow: var(--glow-purple);
}

.message-window-messages .message.received {
  background: var(--message-received);
  margin-right: auto;
  border: 1px solid var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.message-window-messages .message.automated {
  background: var(--message-automated);
  margin-right: auto;
  border: 1px solid rgba(255, 152, 0, 0.3);
  box-shadow: var(--glow-orange);
}

.side-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 30%;
  max-width: 30%;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-magenta);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--glow-magenta);
  backdrop-filter: blur(10px);
}

.input-container {
  padding: 20px !important;
  background: rgba(26, 26, 46, 0.9) !important;
  border-top: 1px solid var(--neon-cyan) !important;
  min-width: 0 !important;
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
}

.message-window-textarea {
  flex: 1 !important;
  min-height: 40px !important;
  max-height: 120px !important;
  padding: 12px 15px !important;
  background: rgba(26, 26, 46, 0.7) !important;
  border: 1px solid var(--neon-cyan) !important;
  border-radius: 12px !important;
  resize: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--text-color) !important;
  transition: all 0.3s ease !important;
  box-shadow: var(--glow-cyan) !important;
}

.message-window-textarea:focus {
  outline: none !important;
  background: rgba(26, 26, 46, 0.9) !important;
  border-color: var(--neon-cyan) !important;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.3) !important;
}

.message-window-action-button {
  padding: 10px 20px !important;
  height: 40px !important;
  border-radius: 12px !important;
  border: 1px solid var(--neon-cyan) !important;
  background: transparent !important;
  color: var(--neon-cyan) !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.message-window-action-button:hover {
  background: rgba(0, 255, 255, 0.1) !important;
  box-shadow: var(--glow-cyan) !important;
  transform: translateY(-2px) !important;
}

/* Continue with specific button styles... */
.message-window-send {
  border-color: var(--neon-green) !important;
  color: var(--neon-green) !important;
}

.message-window-send:hover {
  background: rgba(0, 255, 157, 0.1) !important;
  box-shadow: var(--glow-green) !important;
}

.message-window-ai-help {
  border-color: var(--neon-magenta) !important;
  color: var(--neon-magenta) !important;
}

.message-window-ai-help:hover {
  background: rgba(255, 0, 255, 0.1) !important;
  box-shadow: var(--glow-magenta) !important;
}

.message-content {
  max-width: 100%;
}

.response-actions {
  display: flex !important;
  gap: 8px !important;
  flex-shrink: 0 !important;
}

.ticket-actions {
  display: flex !important;
  gap: 10px !important;
  padding: 15px 20px !important;
  border-top: 1px solid #e0e0e0 !important;
}


.tab-buttons {
  flex: 0 0 auto;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.tab-button {
  flex: 1;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
  font-weight: 500;
}


.tab-button.active {
  color: #6200ea;
  border-bottom: 2px solid #6200ea;
}

.message-window-action-button {
  padding: 8px 12px !important;
  height: 40px !important;
  border-radius: 8px !important;
  border: none !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  white-space: nowrap !important;
}

.message-window-resolve {
  background-color: #28a745 !important;
  color: white !important;
}

.message-window-resolve:hover {
  background-color: #218838 !important;
  box-shadow: 0 2px 4px rgba(40, 167, 69, 0.2) !important;
}

.message-window-close {
  background-color: #dc3545 !important;
  color: white !important;
}

.message-window-close:hover {
  background-color: #c82333 !important;
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2) !important;
}

.message-meta {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  display: flex;
  gap: 10px;
}

.message-status-badge {
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 10px;
  background-color: #ff9800;
  color: white;
  margin-bottom: 5px;
  display: inline-block;
}

.ticket-header {
  padding: 12px 20px !important;
  background: rgba(26, 26, 46, 0.9) !important;
  border-bottom: 1px solid var(--neon-cyan) !important;
  position: relative !important;
}

.ticket-title-row {
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
  margin-bottom: 10px !important;
}

.ticket-title-container {
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  gap: 20px !important;
  min-width: 0 !important; /* Allows flex items to shrink below content size */
}

.ticket-header h2 {
  margin: 0 !important;
  padding: 12px 20px !important;
  background: rgba(26, 26, 46, 0.8) !important;
  border: 1px solid var(--neon-purple) !important;
  border-radius: 12px !important;
  color: var(--text-color) !important;
  font-size: 16px !important;
  box-shadow: var(--glow-purple) !important;
  backdrop-filter: blur(5px) !important;
  flex: 1 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.header-actions {
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
  margin-left: auto !important;
  flex-shrink: 0 !important;
}

.ticket-file-indicators {
  display: flex !important;
  gap: 10px !important;
  flex-wrap: nowrap !important;
}

.ticket-meta {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 15px;
  flex-wrap: wrap;
}

.ticket-status {
  padding: 8px 16px;
  border-radius: 12px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 8px;
  backdrop-filter: blur(5px);
}

.ticket-status.pending {
  background: rgba(63, 81, 181, 0.1);
  color: var(--neon-blue);
  border: 1px solid var(--neon-blue);
  box-shadow: var(--glow-blue);
}

.ticket-status.automated {
  background: rgba(0, 150, 136, 0.1);
  color: var(--neon-cyan);
  border: 1px solid var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.ticket-status.resolved {
  background: rgba(56, 142, 60, 0.1);
  color: var(--neon-green);
  border: 1px solid var(--neon-green);
  box-shadow: var(--glow-green);
}

.ticket-status.closed {
  background: rgba(211, 47, 47, 0.1);
  color: var(--neon-magenta);
  border: 1px solid var(--neon-magenta);
  box-shadow: var(--glow-magenta);
}

.ticket-status.ai_resolved {
  background: rgba(255, 160, 0, 0.1);
  color: var(--neon-orange);
  border: 1px solid var(--neon-orange);
  box-shadow: var(--glow-orange);
}

.ticket-employee {
  padding: 8px 16px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(5px);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.language-selector-container {
  display: none;
}

.translating-message {
  color: #666;
  font-size: 14px;
  font-style: italic;
}

/* Ensure flex items don't overflow their containers */
.message-window-layout, .message-window-content, .message-window-messages, .input-container {
  min-width: 0;
}

.action-menu-container {
  position: relative !important;
  margin-left: auto !important; /* Push to the right */
}

.action-menu-trigger {
  padding: 8px 16px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  cursor: pointer;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.3s ease;
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(5px);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.action-menu-trigger:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
}

.action-menu {
  position: absolute !important;
  top: 0 !important;
  right: 100% !important;
  margin-right: 10px !important;
  background: rgba(26, 26, 46, 0.95) !important;
  border: 1px solid var(--neon-cyan) !important;
  border-radius: 12px !important;
  min-width: 200px !important;
  z-index: 1000 !important;
  box-shadow: var(--glow-cyan) !important;
  backdrop-filter: blur(10px) !important;
  animation: menuAppear 0.3s ease !important;
}

.action-menu button,
.action-menu-item {
  width: 100%;
  padding: 12px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  font-size: 12px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.action-menu button:hover,
.action-menu-item:hover {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.language-submenu {
  position: absolute;
  left: -100% !important;
  top: 0;
  margin-left: -10px !important;
  background: rgba(26, 26, 46, 0.95);
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  min-width: 150px;
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(10px);
  animation: menuAppear 0.3s ease;
}

.draft-actions {
  display: flex !important;
  gap: 8px !important;
  margin-top: 8px !important;
}

.draft-message-button {
  padding: 8px 16px !important;
  border-radius: 12px !important;
  background: rgba(26, 26, 46, 0.8) !important;
  border: 1px solid var(--neon-cyan) !important;
  color: var(--text-color) !important;
  font-size: 12px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  backdrop-filter: blur(5px) !important;
}

.draft-message-button:hover {
  transform: translateY(-2px) !important;
}

.draft-message-button.send {
  border-color: var(--neon-green) !important;
  color: var(--neon-green) !important;
  box-shadow: var(--glow-green) !important;
}

.draft-message-button.send:hover {
  background: rgba(0, 255, 157, 0.1) !important;
}

.draft-message-button.delete {
  border-color: var(--neon-magenta) !important;
  color: var(--neon-magenta) !important;
  box-shadow: var(--glow-magenta) !important;
}

.draft-message-button.delete:hover {
  background: rgba(255, 0, 255, 0.1) !important;
}

.draft-message-button.modify {
  border-color: var(--neon-cyan) !important;
  color: var(--neon-cyan) !important;
  box-shadow: var(--glow-cyan) !important;
}

.draft-message-button.modify:hover {
  background: rgba(0, 255, 255, 0.1) !important;
}

/* Animations */
@keyframes headerGlow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes menuAppear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tab-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.pdf-preview-link {
    display: inline-block;
    margin: 10px 0;
    text-decoration: none;
    color: var(--neon-cyan);
    transition: transform 0.2s ease;
}

.pdf-preview-link:hover {
    transform: translateY(-2px);
}

.pdf-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 120px;
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    margin-bottom: 5px;
    background: rgba(26, 26, 46, 0.8);
    box-shadow: var(--glow-cyan);
    transition: all 0.3s ease;
}

.pdf-preview:hover {
    background: rgba(0, 255, 255, 0.1);
}

.pdf-preview i {
    font-size: 40px;
    margin-bottom: 8px;
}

.pdf-preview .pdf-text {
    font-size: 14px;
    font-weight: 500;
}

.ticket-files {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.file-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.file-button:hover {
    background-color: #e0e0e0;
}

.file-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.file-overlay-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    height: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.file-overlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.file-overlay-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    padding: 4px;
}

.file-overlay-body {
    flex: 1;
    padding: 16px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-overlay-body img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.file-download {
    text-align: center;
}

.download-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 16px;
}

.download-button:hover {
    background-color: #0056b3;
}

.file-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.file-overlay-content {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.close-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.context-tab {
    padding: 20px !important;
    height: 100% !important;
    overflow-y: auto !important;
    background: rgba(26, 26, 46, 0.8) !important;
}

.context-content {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
}

.context-section {
    background: rgba(26, 26, 46, 0.9) !important;
    padding: 20px !important;
    border-radius: 12px !important;
    border: 1px solid var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(10px) !important;
    transition: all 0.3s ease !important;
}

.context-section:hover {
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3) !important;
    transform: translateY(-2px) !important;
}

.context-section h3 {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    border-bottom: 1px solid var(--neon-cyan) !important;
    padding-bottom: 10px !important;
    text-shadow: var(--glow-cyan) !important;
}

.context-section h4 {
    color: var(--neon-magenta) !important;
    font-size: 13px !important;
    margin: 12px 0 8px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-shadow: var(--glow-magenta) !important;
}

.context-section p {
    margin: 8px 0 !important;
    font-size: 13px !important;
    color: var(--text-color) !important;
    line-height: 1.5 !important;
}

.context-section strong {
    color: var(--neon-purple) !important;
    font-weight: 500 !important;
    text-shadow: var(--glow-purple) !important;
}

.ticket-indicators {
    display: flex !important;
    gap: 15px !important;
    margin-top: 15px !important;
    flex-wrap: wrap !important;
}

.ticket-indicator {
    padding: 8px 16px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 12px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    backdrop-filter: blur(5px) !important;
    transition: all 0.3s ease !important;
}

.ticket-indicator.tracking {
    border-color: var(--neon-purple) !important;
    color: var(--neon-purple) !important;
    box-shadow: var(--glow-purple) !important;
}

.ticket-indicator.client {
    border-color: var(--neon-cyan) !important;
    color: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
}

.ticket-indicator.carrier {
    border-color: var(--neon-orange) !important;
    color: var(--neon-orange) !important;
    box-shadow: var(--glow-orange) !important;
}

.ticket-indicator.status {
    border-color: var(--neon-green) !important;
    color: var(--neon-green) !important;
    box-shadow: var(--glow-green) !important;
}

.ticket-indicator.status.off {
    border-color: var(--neon-magenta) !important;
    color: var(--neon-magenta) !important;
    box-shadow: var(--glow-magenta) !important;
    opacity: 0.7 !important;
}

.ticket-indicator:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.2) !important;
}

.ticket-indicator i {
    font-size: 14px !important;
}

/* Add new styles for file indicators */
.ticket-file-indicators {
    display: flex !important;
    gap: 10px !important;
    margin-top: 15px !important;
    flex-wrap: wrap !important;
}

.ticket-file-indicator {
    padding: 8px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-purple) !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    box-shadow: var(--glow-purple) !important;
    position: relative !important;
}

.ticket-file-indicator:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.2) !important;
}

.ticket-file-indicator svg {
    font-size: 18px !important;
    color: var(--neon-purple) !important;
}

.ticket-file-indicator.pdf {
    border-color: var(--neon-magenta) !important;
}

.ticket-file-indicator.pdf svg {
    color: var(--neon-magenta) !important;
}

.ticket-file-indicator.image {
    border-color: var(--neon-cyan) !important;
}

.ticket-file-indicator.image svg {
    color: var(--neon-cyan) !important;
}

.ticket-file-indicator.document {
    border-color: var(--neon-blue) !important;
}

.ticket-file-indicator.document svg {
    color: var(--neon-blue) !important;
}

.ticket-file-tooltip {
    position: absolute !important;
    bottom: calc(100% + 10px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 8px !important;
    padding: 8px 12px !important;
    font-size: 12px !important;
    white-space: nowrap !important;
    opacity: 0 !important;
    visibility: hidden !important;
    transition: all 0.3s ease !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(5px) !important;
    z-index: 1000 !important;
}

.ticket-file-indicator:hover .ticket-file-tooltip {
    opacity: 1 !important;
    visibility: visible !important;
}

.warehouse-tab {
    padding: 1rem;
}

.warehouse-email-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.warehouse-input,
.warehouse-textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.warehouse-textarea {
    resize: vertical;
    min-height: 100px;
}

.warehouse-submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
}

.warehouse-submit-button:hover {
    background-color: #0056b3;
}

.warehouse-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* User Tab Styles */
.user-tab {
    padding: 20px !important;
    height: 100% !important;
    overflow-y: auto !important;
    background: rgba(26, 26, 46, 0.8) !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
}

.user-section {
    background: rgba(26, 26, 46, 0.9) !important;
    padding: 20px !important;
    border-radius: 12px !important;
    border: 1px solid var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(10px) !important;
    transition: all 0.3s ease !important;
}

.user-section:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.3) !important;
}

.user-section h3 {
    margin: 0 0 15px 0 !important;
    padding-bottom: 10px !important;
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    border-bottom: 1px solid var(--neon-cyan) !important;
    text-shadow: var(--glow-cyan) !important;
}

.user-section h4 {
    margin: 15px 0 10px 0 !important;
    color: var(--neon-magenta) !important;
    font-size: 13px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-shadow: var(--glow-magenta) !important;
}

.user-section p {
    margin: 8px 0 !important;
    font-size: 13px !important;
    color: var(--text-color) !important;
    line-height: 1.5 !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}

.user-section strong {
    color: var(--neon-purple) !important;
    font-weight: 500 !important;
    text-shadow: var(--glow-purple) !important;
    min-width: 100px !important;
}

.user-section:nth-child(1) {
    border-color: var(--neon-magenta) !important;
    box-shadow: var(--glow-magenta) !important;
}

.user-section:nth-child(2) {
    border-color: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
}

.user-section:nth-child(3) {
    border-color: var(--neon-purple) !important;
    box-shadow: var(--glow-purple) !important;
}

/* Add icons to the sections */
.user-section p svg {
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    opacity: 0.8 !important;
}

/* Package Content Hover Effect */
.package-content {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}

.package-content-truncated {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 200px !important;
    cursor: pointer !important;
}

.package-content-full {
    position: absolute !important;
    left: 0 !important;
    top: 100% !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 8px !important;
    padding: 12px !important;
    min-width: 250px !important;
    max-width: 400px !important;
    z-index: 1000 !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(10px) !important;
    visibility: hidden !important;
    opacity: 0 !important;
    transition: all 0.3s ease !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}

.package-content:hover .package-content-full {
    visibility: visible !important;
    opacity: 1 !important;
    transform: translateY(5px) !important;
}

.track-tab {
    padding: 20px !important;
    height: 100% !important;
    overflow-y: auto !important;
    background: rgba(26, 26, 46, 0.8) !important;
}

.tracking-section {
    background: rgba(26, 26, 46, 0.9) !important;
    padding: 20px !important;
    border-radius: 12px !important;
    border: 1px solid var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(10px) !important;
}

.tracking-section h3 {
    margin: 0 0 15px 0 !important;
    padding-bottom: 10px !important;
    color: var(--neon-cyan) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    border-bottom: 1px solid var(--neon-cyan) !important;
    text-shadow: var(--glow-cyan) !important;
}

.tracking-section h4 {
    color: var(--neon-magenta) !important;
    font-size: 13px !important;
    margin: 15px 0 10px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    text-shadow: var(--glow-magenta) !important;
}

.tracking-section p {
    margin: 8px 0 !important;
    padding: 8px 16px !important;
    background: rgba(26, 26, 46, 0.8) !important;
    border: 1px solid var(--neon-purple) !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    color: var(--text-color) !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    box-shadow: var(--glow-purple) !important;
}

.tracking-section strong {
    color: var(--neon-purple) !important;
    font-weight: 500 !important;
    text-shadow: var(--glow-purple) !important;
    min-width: 120px !important;
}

.tracking-events {
    position: relative !important;
    padding: 20px 0 !important;
    margin-left: 20px !important;
}

/* Neon path line */
.tracking-events::before {
    content: '' !important;
    position: absolute !important;
    left: 15px !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 2px !important;
    background: var(--neon-cyan) !important;
    box-shadow: var(--glow-cyan) !important;
    z-index: 1 !important;
}

.tracking-event {
    position: relative !important;
    margin: 30px 0 !important;
    padding-left: 45px !important;
}

/* Neon dot for each event */
.tracking-event::before {
    content: '' !important;
    position: absolute !important;
    left: 8px !important;
    top: 50% !important;
    width: 16px !important;
    height: 16px !important;
    background: var(--neon-cyan) !important;
    border-radius: 50% !important;
    transform: translateY(-50%) !important;
    box-shadow: var(--glow-cyan) !important;
    z-index: 2 !important;
}

.event-date {
    font-size: 12px !important;
    color: var(--neon-purple) !important;
    margin-bottom: 4px !important;
    text-shadow: var(--glow-purple) !important;
    font-weight: 500 !important;
}

.event-location {
    font-size: 13px !important;
    color: var(--neon-cyan) !important;
    margin-bottom: 4px !important;
    text-shadow: var(--glow-cyan) !important;
}

.event-status {
    font-size: 14px !important;
    color: var(--neon-cyan) !important;
    padding: 12px 20px !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-magenta) !important;
    border-radius: 8px !important;
    box-shadow: var(--glow-magenta) !important;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5) !important;
    letter-spacing: 0.5px !important;
    font-weight: 500 !important;
    transition: all 0.3s ease !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    max-width: 100% !important;
}

.event-status:hover {
    background: rgba(26, 26, 46, 0.8) !important;
    transform: translateY(-2px) !important;
    box-shadow: 0 0 15px var(--neon-magenta) !important;
}

/* Animation for events */
.tracking-event {
  animation: eventAppear 0.5s ease forwards;
  opacity: 1;
  transform: translateX(-20px);
}


@keyframes eventAppear {
    to {
        opacity: 1 !important;
        transform: translateX(0) !important;
    }
}

/* Stagger animation delay for events */
.tracking-event:nth-child(1) { animation-delay: 0.1s !important; }
.tracking-event:nth-child(2) { animation-delay: 0.2s !important; }
.tracking-event:nth-child(3) { animation-delay: 0.3s !important; }
.tracking-event:nth-child(4) { animation-delay: 0.4s !important; }
.tracking-event:nth-child(5) { animation-delay: 0.5s !important; }

.file-metadata-tooltip {
    position: absolute !important;
    bottom: calc(100% + 10px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: rgba(26, 26, 46, 0.95) !important;
    border: 1px solid var(--neon-cyan) !important;
    border-radius: 8px !important;
    padding: 12px !important;
    font-size: 12px !important;
    white-space: normal !important;
    opacity: 0 !important;
    visibility: hidden !important;
    transition: all 0.3s ease !important;
    box-shadow: var(--glow-cyan) !important;
    backdrop-filter: blur(5px) !important;
    z-index: 1000 !important;
    width: 300px !important;
    max-width: 90vw !important;
}

.ticket-file-indicator:hover .file-metadata-tooltip {
    opacity: 1 !important;
    visibility: visible !important;
}

.metadata-title, .metadata-description {
    margin-bottom: 8px !important;
    line-height: 1.4 !important;
    color: var(--text-color) !important;
}

.metadata-title strong, .metadata-description strong {
    color: var(--neon-purple) !important;
    display: block !important;
    margin-bottom: 4px !important;
    text-shadow: var(--glow-purple) !important;
}

.metadata-description {
    font-size: 11px !important;
}