/* DashboardRedesign.css */
.una-dashboard-container {
  padding: 20px;
  background: var(--gradient-bg);
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;
}

/* Header Stats Grid */
.una-dashboard-header {
  margin-bottom: 20px;
}

.una-dashboard-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.una-stat-card {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transition: all 0.3s ease;
}

.una-stat-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--glow-cyan);
}

.una-stat-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  font-size: 1.5rem;
}

.una-stat-icon.automated {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
}

.una-stat-icon.pending {
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
}

.una-stat-content {
  flex: 1;
}

.una-stat-value {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--neon-green);
  text-shadow: var(--glow-green);
  margin-bottom: 5px;
}

.una-stat-label {
  font-size: 0.9rem;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Agent Status */
.una-agent-status {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
}

.una-agent-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  font-size: 2rem;
}

.una-agent-details {
  flex: 1;
}

.una-agent-name {
  display: block;
  font-size: 1.2rem;
  color: var(--neon-green);
  text-shadow: var(--glow-green);
  margin-bottom: 5px;
}

.una-agent-automation {
  font-size: 0.9rem;
  color: var(--neon-cyan);
}

/* Main Dashboard Grid */
.una-dashboard-main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.una-section {
  background: var(--gradient-card);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  padding: 25px;
}

.una-section-header {
  margin-bottom: 20px;
}

.una-section-header h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--neon-cyan);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-cyan);
}

.una-section-header h2 svg {
  color: var(--neon-green);
  filter: drop-shadow(var(--glow-green));
}

/* Activity Grid */
.una-activity-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.una-activity-card {
  background: rgba(26, 26, 46, 0.7);
  border-radius: 15px;
  padding: 20px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.una-activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.una-activity-header h3 {
  color: var(--neon-cyan);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Activity Items */
.una-activity-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(26, 26, 46, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.una-activity-item:hover {
    background: rgba(0, 255, 157, 0.05);
    border-color: var(--neon-green);
    transform: translateX(5px);
}

.una-activity-item-main {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

.una-activity-item-image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
}

.una-activity-item-title {
    color: var(--neon-cyan);
    font-size: 0.9rem;
    font-weight: 500;
}

.una-activity-item-subtitle {
    color: var(--text-color);
    font-size: 0.8rem;
    opacity: 0.7;
}

.una-activity-item-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}

.una-activity-item-price {
    color: var(--neon-green);
    font-weight: 500;
}

.una-status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.una-status-badge.paid {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-green);
}

.una-status-badge.pending {
    background: rgba(255, 165, 0, 0.1);
    color: orange;
}

.una-status-badge.refunded {
    background: rgba(255, 0, 0, 0.1);
    color: #ff4444;
}

.una-orders-list,
.una-returns-list {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 5px;
}

/* Scrollbar styling */
.una-orders-list::-webkit-scrollbar,
.una-returns-list::-webkit-scrollbar {
    width: 6px;
}

.una-orders-list::-webkit-scrollbar-track,
.una-returns-list::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.7);
    border-radius: 3px;
}

.una-orders-list::-webkit-scrollbar-thumb,
.una-returns-list::-webkit-scrollbar-thumb {
    background: var(--neon-green);
    border-radius: 3px;
}

/* Responsive Design */
@media (max-width: 1400px) {
    .una-dashboard-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }
}

@media (max-width: 1200px) {
    .una-dashboard-stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 992px) {
    .una-dashboard-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .una-dashboard-container {
        padding: 10px;
    }
    
    .una-dashboard-grid {
        grid-template-columns: 1fr;
    }
}

/* Animation for expanded cards */
@keyframes cardGlow {
  0% {
    box-shadow: var(--glow-green);
  }
  50% {
    box-shadow: var(--glow-cyan);
  }
  100% {
    box-shadow: var(--glow-green);
  }
}

.una-dashboard-card.expanded {
  animation: cardGlow 2s infinite;
}

.una-dashboard-grid {
    display: grid;
    grid-template-columns: minmax(0, 33.33%) minmax(0, 66.66%);
    gap: 20px;
}

.una-activity-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.una-dashboard-card {
    background: rgba(26, 26, 46, 0.7);
    border-radius: 15px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
    padding: 20px;
    height: calc(100vh - 200px);
}

.una-card-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 255, 157, 0.2);
    margin-bottom: 20px;
}

.una-card-header svg {
    font-size: 1.4rem;
    color: var(--neon-green);
    filter: drop-shadow(0 0 5px rgba(0, 255, 157, 0.5));
}

.una-card-header .tickets-icon {
    color: var(--neon-cyan);
    filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.5));
}

.una-card-header .orders-icon {
    color: var(--neon-magenta);
    filter: drop-shadow(0 0 5px rgba(255, 0, 255, 0.5));
}

.una-card-header .returns-icon {
    color: var(--neon-green);
    filter: drop-shadow(0 0 5px rgba(0, 255, 157, 0.5));
}

.una-view-all {
    background: transparent;
    border: 1px solid var(--neon-green);
    border-radius: 4px;
    color: var(--neon-green);
    padding: 4px 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.una-view-all svg {
    font-size: 0.8rem;
    color: var(--neon-green);
}

.una-view-all:hover {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: 0 0 10px rgba(0, 255, 157, 0.2);
    transform: translateY(-1px);
}

.una-card-content {
    height: calc(100% - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.una-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(26, 26, 46, 0.9);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 10px;
    padding: 12px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.una-list-item:hover {
    border-color: var(--neon-green);
    box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
    transform: translateX(5px);
}

/* Ticket item specific */
.una-ticket-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.una-ticket-subject {
    color: var(--neon-cyan);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
}

/* Order item specific */
.una-order-info {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1;
}

.una-order-main {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 0;
}

.una-order-number {
    color: var(--neon-cyan);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
    white-space: nowrap;
}

.una-order-customer {
    color: var(--neon-magenta);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(255, 0, 255, 0.5);
    white-space: nowrap;
}

.una-order-details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.una-order-price {
    color: var(--neon-green);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(0, 255, 157, 0.5);
    white-space: nowrap;
}

.una-status-badge {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-shadow: 0 0 5px rgba(0, 255, 157, 0.5);
}

.una-status-badge.automated {
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-cyan);
    border: 1px solid var(--neon-cyan);
}

.una-status-badge.partially_paid {
    background: rgba(255, 165, 0, 0.1);
    color: #ffa500;
    border: 1px solid #ffa500;
    text-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
}

/* Return Status Badges */
.una-status-badge.label-created {
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-green);
    border: 1px solid var(--neon-green);
}

.una-status-badge.completed {
  background-color: #2ecc71;
  color: white;
}

.una-status-badge.cancelled {
  background-color: #e74c3c;
  color: white;
}

.una-status-badge.processing {
  background-color: #9b59b6;
  color: white;
}

.una-status-badge.shipped {
  background-color: #27ae60;
  color: white;
}

/* Add any other return statuses your system uses */

/* Scrollbar */
.una-card-content::-webkit-scrollbar {
    width: 5px;
}

.una-card-content::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.7);
}

.una-card-content::-webkit-scrollbar-thumb {
    background: var(--neon-green);
    border-radius: 3px;
}

/* Responsive */
@media (max-width: 1200px) {
    .una-dashboard-grid {
        grid-template-columns: 100%;
    }
    
    .una-activity-section {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .una-activity-section {
        grid-template-columns: 100%;
    }
}

/* Add to existing CSS */
.una-attention-section {
    margin-top: 20px;
    width: 100%;
}

.una-product-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.una-product-title {
    color: var(--neon-cyan);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.una-product-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.una-reason-tag {
    padding: 3px 8px;
    border-radius: 12px;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.una-reason-tag.out_of_stock {
    background: rgba(255, 0, 0, 0.1);
    color: #ff4444;
    border: 1px solid #ff4444;
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.una-reason-tag.low_stock {
    background: rgba(255, 165, 0, 0.1);
    color: #ffa500;
    border: 1px solid #ffa500;
    text-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
}

.una-reason-tag.low_sales_velocity {
    background: rgba(255, 0, 255, 0.1);
    color: #ff00ff;
    border: 1px solid #ff00ff;
    text-shadow: 0 0 5px rgba(255, 0, 255, 0.5);
}

.una-product-metrics {
    display: flex;
    align-items: center;
    gap: 15px;
}

.una-metric {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--neon-green);
    font-size: 0.8rem;
    text-shadow: 0 0 5px rgba(0, 255, 157, 0.5);
}

.una-metric svg {
    font-size: 0.9rem;
}

.una-return-main {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.una-return-order {
    font-weight: 600;
    color: #666;
}

.una-return-title {
    font-size: 0.9em;
}

.una-return-tracking {
    font-size: 0.8em;
    color: #666;
}

.una-return-details {
    display: flex;
    align-items: center;
    gap: 12px;
}

.una-return-price {
    font-weight: 600;
}

.full-width {
    width: 100% !important;
    grid-column: 1 / -1;
}

/* Add to existing CSS */
.una-stats-content {
    width: 100%;
    padding: 20px;
}

.una-stats-metrics {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 15px;
    margin-bottom: 10px;
}

.una-metric-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.una-metric-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: rgba(13, 14, 26, 0.7);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 157, 0.1);
}

.una-metric-item svg {
    font-size: 1.2rem;
    color: var(--neon-cyan);
    filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.5));
}

.una-metric-value {
    font-size: 1rem;
    font-weight: 600;
    color: var(--neon-green);
    text-shadow: 0 0 5px rgba(0, 255, 157, 0.5);
}

.una-metric-label {
    font-size: 0.65rem;
    color: var(--text-color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.una-tags-chart {
    height: 130px;
    background: rgba(13, 14, 26, 0.7);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    padding: 10px;
}

.una-usage-progress-bar {
    width: 100%;
    height: 25px;
    background: rgba(13, 14, 26, 0.7);
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgba(0, 255, 157, 0.1);
}

.una-progress {
    height: 100%;
    background: linear-gradient(90deg, var(--neon-green), var(--neon-cyan));
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease;
}

.una-progress-text {
    color: #1a1a2e;
    font-weight: 600;
    font-size: 0.8rem;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

@media (max-width: 1200px) {
    .una-stats-metrics {
        grid-template-columns: 1fr;
    }
    
    .una-metric-group {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .una-metric-group {
        grid-template-columns: 1fr;
    }
}

.una-stats-section {
    margin-bottom: 15px;
}

.una-stats-section h4 {
    color: var(--neon-cyan);
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.una-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.una-metric-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Update existing metric item styles */
.una-metric-item {
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease;
}

.una-metric-item:hover {
    border-color: var(--neon-cyan);
    transform: translateY(-2px);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

/* Responsive adjustments */
@media (max-width: 1400px) {
    .una-stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .una-stats-grid {
        grid-template-columns: 1fr;
    }
    
    .una-metric-group {
        gap: 15px;
    }
}

/* Client Stats Section */
.una-top-clients-section {
    margin-top: 20px;
    background: rgba(13, 14, 26, 0.7);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    padding: 15px;
}

.una-top-clients-section h4 {
    color: var(--neon-cyan);
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.una-top-clients-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.una-client-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(26, 26, 46, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
}

.una-client-item:hover {
    border-color: var(--neon-cyan);
    transform: translateX(5px);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.una-client-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.una-client-name {
    color: var(--neon-cyan);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.3);
}

.una-client-tickets {
    color: var(--neon-green);
    font-size: 0.8rem;
    text-shadow: 0 0 5px rgba(0, 255, 157, 0.3);
}

.una-client-sentiment {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--neon-magenta);
    font-size: 0.9rem;
    text-shadow: 0 0 5px rgba(255, 0, 255, 0.3);
}

.una-client-sentiment svg {
    filter: drop-shadow(0 0 3px rgba(255, 0, 255, 0.5));
}

/* Scrollbar for clients list */
.una-top-clients-list::-webkit-scrollbar {
    width: 4px;
}

.una-top-clients-list::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.7);
    border-radius: 2px;
}

.una-top-clients-list::-webkit-scrollbar-thumb {
    background: var(--neon-cyan);
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.una-stats-section h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 0;
    transition: background-color 0.3s ease;
}

.una-stats-section h4:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.una-tags-chart h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 0;
    transition: background-color 0.3s ease;
}

.una-tags-chart h4:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.una-stat-card.collapsed {
    padding: 10px;
}

.una-stat-card.collapsed .una-stats-content {
    display: none;
}

.una-collapse-button {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--gradient-card);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 0 0 20px 20px;
    padding: 2px 15px;
    cursor: pointer;
    z-index: 1;
}

.una-collapse-icon {
    color: var(--neon-cyan);
    transition: transform 0.3s ease;
    display: block;
    font-size: 12px;
}

.una-collapse-icon.collapsed {
    transform: rotate(180deg);
}

.una-dashboard-loading-screen {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    padding: 25px;
}

.una-loading-content {
    text-align: center;
    padding: 40px;
    width: 100%;
    max-width: 600px;
}

.una-loading-icons {
    position: relative;
    height: 80px;
    margin-bottom: 30px;
}

.una-loading-icons svg {
    position: absolute;
    font-size: 2rem;
    filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
    top: 0;
    left: 30%;
    color: var(--neon-cyan);
    animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
    bottom: 0;
    left: 20%;
    color: var(--neon-green);
    animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
    bottom: 0;
    right: 20%;
    color: var(--neon-magenta);
    animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 2em;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.una-loading-progress {
    width: 100%;
    height: 4px;
    background: rgba(0, 255, 157, 0.1);
    border-radius: 2px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;
}

.una-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    background: var(--neon-cyan);
    border-radius: 2px;
    animation: progress 2s infinite;
}

.una-progress-glow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba(0, 255, 255, 0.4), transparent);
    animation: glow 1.5s infinite;
}

.una-loading-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.una-loading-metrics .una-metric-item {
    animation: fadeIn 0.5s ease-out forwards;
    opacity: 0;
}

.una-loading-metrics .una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-loading-metrics .una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-loading-metrics .una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-loading-metrics .una-metric-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes progress {
    0% { left: -30%; }
    100% { left: 100%; }
}

@keyframes glow {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}

@keyframes fadeIn {
    from { 
        opacity: 0;
        transform: translateY(10px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes floatIcon {
    0%, 100% { 
        transform: translateY(0) scale(1); 
        opacity: 1;
    }
    50% { 
        transform: translateY(-10px) scale(1.1); 
        opacity: 0.8;
    }
}

.una-metric-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.una-metric-value {
    font-size: 1rem;
    font-weight: 600;
    color: var(--neon-green);
    text-shadow: 0 0 5px rgba(0, 255, 157, 0.5);
    line-height: 1.2;
}

.una-metric-label {
    font-size: 0.65rem;
    color: var(--text-color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 1.2;
}

.una-stats-section {
    margin-bottom: 25px;
}

.una-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}