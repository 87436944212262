.sidebar {
  width: 60px;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  z-index: 1000;
  border-right: 1px solid rgba(0, 255, 157, 0.1);
  backdrop-filter: blur(10px);
}

.sidebar.expanded {
  width: 250px;
}

.sidebar-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: rgba(13, 14, 26, 0.95);
  border-bottom: 1px solid rgba(0, 255, 157, 0.1);
}

.sidebar-header .logo {
  max-width: 80%;
  height: auto;
  opacity: 0;
  display: none;
  transition: opacity 0.2s ease;
}

.sidebar.expanded .sidebar-header .logo {
  opacity: 1;
  display: block;
}

.sidebar-nav {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav li {
  margin-bottom: 5px;
}

.sidebar-nav a {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: auto;
  min-width: 40px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin: 5px 10px;
  border-radius: 12px;
  color: #00ff9d;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.4);
  transition: all 0.3s ease;
}

.sidebar-nav a:hover {
  background-color: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 30px rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
}

.sidebar-nav a.active {
  background-color: rgba(0, 255, 157, 0.15);
  border: 1px solid rgba(0, 255, 157, 0.3);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.2);
}

.sidebar-nav a svg {
  margin-right: 10px;
  color: #00ffff;
  filter: drop-shadow(0 0 8px rgba(0, 255, 255, 0.4));
}

.sidebar-footer {
  margin-top: auto;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(13, 14, 26, 0.95);
  border-top: 1px solid rgba(0, 255, 157, 0.1);
  padding: 15px 10px;
}

.language-switcher {
  width: 100%;
  padding: 5px;
}

.language-switcher select {
  display: none;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  color: #fff;
  border-radius: 10px;
  padding: 8px;
}

.language-switcher select:focus {
  border-color: #00ff9d;
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
}

.sidebar.expanded .language-switcher select {
  display: block;
  width: calc(100% - 40px);
  margin-left: 10px;
}

.logout-button {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ff00ff;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.4);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.logout-button:hover {
  background-color: rgba(255, 0, 255, 0.1);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.2);
  transform: translateY(-2px);
}

.logout-button svg {
  margin-right: 10px;
}
.help-overlay {
  position: absolute;
  background-color: rgba(13, 14, 26, 0.95);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 12px;
  box-shadow: 0 0 40px rgba(0, 255, 157, 0.1);
  color: #00ff9d;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.4);
  padding: 10px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  text-align: center;
  z-index: 10;
}

/* Hide all text and non-icon elements by default */
.sidebar span,
.sidebar select,
.sidebar .switch,
.sidebar .switch-label,
.sidebar .help-overlay,
.sidebar .logo {
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

/* Show elements only when sidebar is expanded */
.sidebar.expanded span,
.sidebar.expanded select,
.sidebar.expanded .switch,
.sidebar.expanded .switch-label,
.sidebar.expanded .logo {
  display: inline-block;
  opacity: 1;
}

/* Center all icons in collapsed state */
.sidebar-nav a,
.language-switcher,
.logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* Adjust alignment when expanded */
.sidebar.expanded .sidebar-nav a,
.sidebar.expanded .language-switcher,
.sidebar.expanded .logout-button {
  justify-content: flex-start;
  padding: 10px 20px;
}

/* Ensure icons are always visible and properly sized */
.sidebar svg {
  font-size: 1.5rem;
  min-width: 1.5rem;
  margin-right: 0;
}

.sidebar.expanded svg {
  margin-right: 10px;
}

/* Adjust header logo for collapsed state */
.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.sidebar.expanded .sidebar-header {
  padding: 20px;
}

.icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

.icon svg {
  width: 20px;
  height: 20px;
  display: block;
}

.text {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-left: 0;
}

.sidebar.expanded .text {
  opacity: 1;
}

/* Center icons when collapsed */
.sidebar .sidebar-nav a {
  justify-content: center;
}

.sidebar.expanded .sidebar-nav a {
  justify-content: flex-start;
  padding: 10px 20px;
}

/* Adjust icon margins */
.sidebar:not(.expanded) .icon {
  margin-right: 0;
}

/* Hide all text elements by default */
.sidebar .text,
.sidebar select,
.sidebar .switch,
.sidebar .switch-label,
.sidebar .help-overlay,
.sidebar .logo {
  display: none;
}

/* Show text elements on hover */
.sidebar.expanded .text,
.sidebar.expanded select,
.sidebar.expanded .switch,
.sidebar.expanded .switch-label,
.sidebar.expanded .logo {
  display: inline-block;
}

/* Adjust main content margin */
.main-content {
  margin-left: 60px;
  transition: margin-left 0.3s ease;
  width: calc(100% - 60px);
}

.sidebar.expanded + .main-content {
  margin-left: 250px;
  width: calc(100% - 250px);
}

/* Ensure icons are always visible */
.sidebar .icon,
.sidebar .icon svg {
  display: flex;
  opacity: 1;
  margin-right: 0;
  min-width: 24px;
  width: 24px;
  height: 24px;
}

/* Remove any hiding of icons */
.sidebar svg {
  font-size: 1.5rem;
  min-width: 1.5rem;
  opacity: 1;
  display: block;
}

/* Adjust icon container for both collapsed and expanded states */
.sidebar-nav a,
.language-switcher,
.logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

/* Remove any potential icon hiding from other rules */
.sidebar:not(.expanded) .icon,
.sidebar:not(.expanded) svg {
  display: flex;
  opacity: 1;
}

/* Adjust spacing when sidebar is expanded */
.sidebar.expanded .icon {
  margin-right: 12px;
}

.toggle-button {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #00ffff;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.toggle-button:hover {
  background-color: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Update the expanded state width */
.sidebar.expanded .sidebar-nav a {
  width: 230px;
}

/* Ensure no horizontal overflow */
.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-nav {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
