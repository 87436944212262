:root {
  --neon-cyan: #00ffff;
  --neon-green: #00ff9d;
  --neon-magenta: #ff00ff;
  --glow-cyan: 0 0 10px rgba(0, 255, 255, 0.4);
  --glow-green: 0 0 10px rgba(0, 255, 157, 0.4);
  --glow-magenta: 0 0 10px rgba(255, 0, 255, 0.4);
}

.una-live-list-container {
  padding: 25px;
  margin-top: 20px;
}

.una-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.una-list-header h2 {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--neon-cyan);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: var(--glow-cyan);
}

.una-create-button {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
  padding: 10px 20px;
  border-radius: 12px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-shadow: var(--glow-green);
}

.una-create-button:hover {
  background: rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
  box-shadow: var(--glow-green);
}

.una-lists-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.una-list-card {
  background: rgba(13, 14, 26, 0.7);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 12px;
  padding: 20px;
  transition: all 0.3s ease;
}

.una-list-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 255, 157, 0.1);
  border-color: var(--neon-green);
}

.una-list-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.una-list-card-header h3 {
  color: var(--neon-cyan);
  font-size: 1.2em;
  text-shadow: var(--glow-cyan);
}

.una-share-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
  border: 1px solid rgba(255, 0, 255, 0.2);
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: var(--glow-magenta);
}

.una-share-button:hover {
  background: rgba(255, 0, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: var(--glow-magenta);
}

.una-list-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.una-list-details p {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9em;
}

.una-list-details svg {
  color: var(--neon-cyan);
}

.una-platform-tag {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  align-self: flex-start;
}

.una-products-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 15px;
}

.una-product-preview {
  background: rgba(0, 255, 157, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  transition: all 0.3s ease;
}

.una-product-preview:hover {
  background: rgba(0, 255, 157, 0.1);
  transform: translateY(-2px);
}

.una-product-thumbnail {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 8px;
}

.una-product-preview span {
  display: block;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.una-more-products {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
  border: 1px solid rgba(255, 0, 255, 0.2);
  border-radius: 8px;
  padding: 8px;
  font-size: 0.9em;
  text-shadow: var(--glow-magenta);
}

.una-list-description {
  margin-top: 15px;
  padding: 12px;
  background: rgba(0, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9em;
}

.una-error {
  color: var(--neon-magenta);
  text-shadow: var(--glow-magenta);
  text-align: center;
  padding: 20px;
  background: rgba(255, 0, 255, 0.1);
  border-radius: 12px;
  border: 1px solid rgba(255, 0, 255, 0.2);
}

.una-loading {
  text-align: center;
  padding: 20px;
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  background: rgba(0, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 255, 0.1);
}

.una-list-card-actions {
  display: flex;
  gap: 8px;
}

.una-toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(0, 255, 157, 0.1);
  border: 1px solid rgba(0, 255, 157, 0.2);
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.una-toggle-button.active {
  background: rgba(255, 0, 255, 0.1);
  color: var(--neon-magenta);
  border: 1px solid rgba(255, 0, 255, 0.2);
  text-shadow: var(--glow-magenta);
}

.una-toggle-button.active:hover {
  background: rgba(255, 0, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: var(--glow-magenta);
}

.una-toggle-button.inactive {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
  text-shadow: var(--glow-green);
}

.una-toggle-button.inactive:hover {
  background: rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
  box-shadow: var(--glow-green);
}

@media (max-width: 768px) {
  .una-lists-grid {
    grid-template-columns: 1fr;
  }
  
  .una-list-header {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  
  .una-create-button {
    width: 100%;
    text-align: center;
  }
}
