.una-order-details-container {
    padding: 25px;
    margin: 20px auto;
    max-width: 1400px;
  }
  
  .una-back-button {
    background: transparent;
    border: 1px solid var(--neon-cyan);
    color: var(--neon-cyan);
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }
  
  .una-back-button:hover {
    background: rgba(0, 255, 255, 0.1);
    box-shadow: var(--glow-cyan);
  }
  
  .una-order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  }
  
  .una-order-header h1 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 2em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-status-badge {
    background: rgba(13, 14, 26, 0.7);
    padding: 8px 16px;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
    margin-left: 10px;
  }
  
  .una-status-badge.paid {
    color: var(--neon-green);
    border: 1px solid var(--neon-green);
    text-shadow: var(--glow-green);
  }
  
  .una-status-badge.pending {
    color: #ffa500;
    border: 1px solid #ffa500;
    text-shadow: 0 0 10px rgba(255, 165, 0, 0.5);
  }
  
  .una-status-badge.unfulfilled {
    color: var(--neon-magenta);
    border: 1px solid var(--neon-magenta);
    text-shadow: var(--glow-magenta);
  }
  
  .una-order-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
  }
  
  .una-summary-card,
  .una-items-card,
  .una-note-card,
  .una-cancelled-card {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .una-summary-card h3,
  .una-items-card h3,
  .una-note-card h3,
  .una-cancelled-card h3 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-summary-card p {
    color: #fff;
    margin: 5px 0;
  }
  
  .una-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-item:last-child {
    border-bottom: none;
  }
  
  .una-item-details h4 {
    color: var(--neon-cyan);
    margin-bottom: 5px;
  }
  
  .una-item-details p {
    color: #fff;
    margin: 3px 0;
  }
  
  .una-item-price {
    color: var(--neon-green);
    text-shadow: var(--glow-green);
    font-size: 1.2em;
  }
  
  .una-discount {
    color: var(--neon-magenta) !important;
    text-shadow: var(--glow-magenta);
  }
  
  .una-order-totals {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-total-line {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    color: #fff;
  }
  
  .una-total-line.total {
    color: var(--neon-green);
    text-shadow: var(--glow-green);
    font-size: 1.2em;
    font-weight: 500;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-cancelled-card {
    border-color: rgba(255, 0, 0, 0.2);
    color: #ff3e3e;
  }
  
  @media (max-width: 1024px) {
    .una-order-grid {
      grid-template-columns: 1fr;
    }
  }

  .una-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .una-refund-button {
    background: transparent;
    border: 1px solid var(--neon-green);
    color: var(--neon-green);
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
  }

  .una-refund-button:hover:not(:disabled) {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
  }

  .una-refund-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: var(--text-color);
    color: var(--text-color);
  }

  .una-item input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid var(--neon-cyan);
    border-radius: 4px;
    background: transparent;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }

  .una-item input[type="checkbox"]:checked {
    background: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
  }

  .una-item input[type="checkbox"]:checked::after {
    content: "✓";
    position: absolute;
    color: black;
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .una-item input[type="number"] {
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid var(--neon-cyan);
    border-radius: 4px;
    color: var(--text-color);
    padding: 5px 10px;
    width: 80px;
    font-size: 0.9rem;
    margin-top: 8px;
  }

  .una-item input[type="number"]:focus {
    outline: none;
    box-shadow: var(--glow-cyan);
  }

  .una-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .una-modal {
    background: var(--bg-card);
    border: 1px solid var(--neon-green);
    box-shadow: var(--glow-green);
    border-radius: 12px;
    padding: 25px;
    width: 90%;
    max-width: 500px;
  }

  .una-modal h2 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .una-modal-content {
    margin-bottom: 20px;
  }

  .una-modal-content textarea {
    width: 100%;
    min-height: 100px;
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid var(--neon-cyan);
    border-radius: 8px;
    color: var(--text-color);
    padding: 10px;
    margin-bottom: 15px;
    resize: vertical;
  }

  .una-modal-content textarea:focus {
    outline: none;
    box-shadow: var(--glow-cyan);
  }

  .una-modal-content label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-color);
  }

  .una-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }

  .una-modal-actions button {
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
  }

  .una-modal-actions button:first-child {
    background: transparent;
    border: 1px solid var(--neon-magenta);
    color: var(--neon-magenta);
  }

  .una-modal-actions button:first-child:hover {
    background: rgba(255, 0, 255, 0.1);
    box-shadow: var(--glow-magenta);
  }

  .una-modal-actions button:last-child {
    background: transparent;
    border: 1px solid var(--neon-green);
    color: var(--neon-green);
  }

  .una-modal-actions button:last-child:hover {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
  }

  .una-error {
    color: var(--neon-magenta);
    text-shadow: var(--glow-magenta);
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid var(--neon-magenta);
    border-radius: 8px;
    background: rgba(255, 0, 255, 0.1);
  }