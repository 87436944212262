/* src/Payment.css */

.payment-container {
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 3% auto;
}

.subscription-plan h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: bold;
}

.billing-frequency {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.billing-frequency .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0 15px;
}

.billing-frequency .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.billing-frequency .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.billing-frequency input:checked + .slider {
    background-color: #6f42c1;
}

.billing-frequency input:checked + .slider:before {
    transform: translateX(26px);
}

.billing-frequency span {
    font-size: 18px;
    color: #333;
    font-weight: bold;
}

.subscription-tiers {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 15px;
    margin-top: 30px;
}

.subscription-tier {
    flex: 1;
    border: 1px solid #e0e0e0;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 200px;
    max-width: 250px;
}

.subscription-tier:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.most-chosen {
    border-color: #6f42c1;
    box-shadow: 0 0 20px rgba(111, 66, 193, 0.2);
}

.most-chosen-title {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #6f42c1;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
}

.subscription-tier h3 {
    margin-bottom: 15px;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.subscription-tier .price {
    font-size: 24px;
    color: #6f42c1;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

.features-list {
    text-align: left;
    margin-bottom: 15px;
    padding-left: 15px;
}

.features-list li {
    margin-bottom: 8px;
    color: #555;
    font-size: 12px;
}

.button-container {
    text-align: center;
}

.checkout-button,
.upgrade-button,
.downgrade-button {
    background: #6f42c1;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 25px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.checkout-button:hover,
.upgrade-button:hover,
.downgrade-button:hover {
    background: #5a32a3;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.cancel-subscription-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 25px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 30px auto 0;
    width: 200px;
}

.cancel-subscription-button:hover {
    background-color: #c82333;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.current-plan {
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 12px;
}

.current-plan h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.current-plan p {
    font-size: 18px;
    color: #555;
    margin-bottom: 5px;
}

.upgrade-options,
.downgrade-options {
    margin-top: 40px;
}

.upgrade-options h3,
.downgrade-options h3 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Modal styles */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}



.close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close-button:hover,
.close-button:focus {
    color: #000;
    text-decoration: none;
}

.modal h3 {
    margin-top: 0;
    color: #333;
    font-size: 24px;
    font-weight: bold;
}

.modal p {
    color: #555;
    font-size: 16px;
}

.cancel-confirmation-input,
.downgrade-confirmation-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.confirm-cancel-button,
.cancel-dialog-close-button,
.confirm-downgrade-button {
    background: #6f42c1;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 25px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.confirm-cancel-button:hover,
.cancel-dialog-close-button:hover,
.confirm-downgrade-button:hover {
    background: #5a32a3;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.cancel-dialog-close-button {
    background-color: #dc3545;
}

.cancel-dialog-close-button:hover {
    background-color: #c82333;
}

/* Responsive design */
@media (max-width: 768px) {
    .subscription-tiers {
        flex-direction: column;
        align-items: center;
    }

    .subscription-tier {
        width: 100%;
        max-width: none;
        margin-bottom: 20px;
    }
}
