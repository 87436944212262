.pdf-form-dashboard {
  padding: 30px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* Cyberpunk Grid Effect */
.pdf-form-dashboard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 30px 30px;
  opacity: 0.1;
  pointer-events: none;
}

.pdf-form-card-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.section-title {
  color: var(--neon-cyan);
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-shadow: var(--glow-cyan);
}

.section-icon {
  font-size: 1.2em;
  filter: drop-shadow(0 0 5px var(--neon-cyan));
}

/* Upload Form */
.upload-form {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  backdrop-filter: blur(10px);
  display: flex;
  gap: 20px;
  align-items: center;
  box-shadow: var(--glow-cyan);
}

.upload-label {
  flex: 1;
  padding: 20px;
  border: 2px dashed var(--neon-cyan);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.upload-label:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: translateY(-2px);
}

.upload-input {
  display: none;
}

.upload-button {
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  padding: 15px 30px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.upload-button:hover:not(:disabled) {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

.upload-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* PDF Cards */
.pdf-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 25px;
}

.pdf-card {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.pdf-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(0, 255, 255, 0.1) 45%,
    rgba(0, 255, 255, 0.2) 50%,
    rgba(0, 255, 255, 0.1) 55%,
    transparent 100%
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.pdf-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--glow-cyan);
}

.pdf-card:hover::before {
  transform: translateX(100%);
}

.pdf-info {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.pdf-icon {
  color: var(--neon-magenta);
  font-size: 24px;
  filter: drop-shadow(0 0 5px var(--neon-magenta));
}

.pdf-name {
  color: var(--text-color);
  font-size: 1.1em;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

/* Fields Section */
.pdf-fields {
  margin-top: 20px;
}

.pdf-fields h4 {
  color: var(--neon-green);
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-shadow: var(--glow-green);
}

.fields-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.field-item {
  background: rgba(26, 26, 46, 0.6);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.field-item:hover {
  background: rgba(0, 255, 255, 0.1);
  border-color: var(--neon-cyan);
  transform: translateY(-2px);
}

.field-type-icon {
  color: var(--neon-cyan);
  font-size: 16px;
  filter: drop-shadow(0 0 3px var(--neon-cyan));
}

.field-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.field-name {
  color: var(--text-color);
  font-size: 0.9em;
  font-weight: 500;
}

.field-type {
  color: var(--neon-magenta);
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.8;
}

/* Delete Button */
.delete-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: var(--neon-magenta);
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.6;
  font-size: 18px;
}

.delete-button:hover:not(:disabled) {
  opacity: 1;
  transform: scale(1.1);
  filter: drop-shadow(0 0 5px var(--neon-magenta));
}

/* Feedback Messages */
.feedback-message {
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: slideIn 0.3s ease;
}

.feedback-message.error {
  background: rgba(255, 0, 85, 0.1);
  border: 1px solid #ff0055;
  color: #ff0055;
}

.feedback-message.success {
  background: rgba(0, 255, 157, 0.1);
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Spinner Animation */
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .upload-form {
    flex-direction: column;
  }
  
  .pdf-list {
    grid-template-columns: 1fr;
  }
}