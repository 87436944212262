.una-tickets-container {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
}

.una-tickets-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 30px;
}

.una-stat-card {
    background: var(--gradient-card);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.una-stat-icon {
    font-size: 1.8rem;
    color: var(--neon-cyan);
    filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.5));
}

.una-stat-icon.automated {
    color: var(--neon-green);
    filter: drop-shadow(0 0 5px rgba(0, 255, 157, 0.5));
}

.una-stat-icon.pending {
    color: var(--neon-magenta);
    filter: drop-shadow(0 0 5px rgba(255, 0, 255, 0.5));
}

.una-stat-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.una-stat-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--neon-cyan);
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.una-stat-label {
    color: var(--text-color);
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.una-tickets-table {
    background: var(--gradient-card);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
    padding: 20px;
}

/* Loading and Error states */
.una-loading,
.una-error {
    text-align: center;
    padding: 40px;
    color: var(--neon-cyan);
    font-size: 1.2rem;
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
}

.una-error {
    color: var(--neon-red);
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

/* Responsive */
@media (max-width: 1200px) {
    .una-tickets-container {
        width: 95%;
    }
}

@media (max-width: 768px) {
    .una-tickets-stats {
        grid-template-columns: 1fr;
    }
}