/* PrivacyPolicy.css */

.privacy-policy {
    background: linear-gradient(135deg, #000000, #2c003e);
    color: #ffffff;
    padding: 50px 20px; /* Adds 20px horizontal padding */
    border-radius: 8px;
    max-width: 100%;
    margin: 0 auto; /* Centers the privacy policy container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center; /* Centers the content inside the container */
}

.privacy-policy img.unanimityai-logo {
    width: 150px; /* Adjusts the size of the logo */
    margin: 0 auto; /* Centers the logo */
    display: block; /* Ensures the logo is a block element */
}

.privacy-policy h1 {
    margin-top: 20px; /* Adds some spacing above the title */
}

.privacy-policy p,
.privacy-policy ul,
.privacy-policy li,
.privacy-policy h2,
.privacy-policy h3,
.privacy-policy address {
    color: #e0e0e0;
    text-align: left; /* Aligns text elements to the left for readability */
    padding: 0 100px; /* Adds horizontal padding to paragraphs, lists, and address */
}

.privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
}

.privacy-policy ul li {
    margin-bottom: 10px;
}

.privacy-policy address {
    font-style: normal;
}
