.una-shop-statistics {
    padding: 25px;
    margin: 20px auto;
    max-width: 1400px;
  }
  
  .una-stats-header {
    margin-bottom: 30px;
  }
  
  .una-shop-info h1 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 2em;
    margin-bottom: 5px;
  }
  
  .una-shop-info p {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .una-plan-badge {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-green);
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 10px;
    display: inline-block;
  }
  
  .una-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .una-stat-card {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    transition: all 0.3s ease;
  }
  
  .una-stat-card:hover {
    transform: translateY(-2px);
    box-shadow: var(--glow-cyan);
  }
  
  .una-stat-card svg {
    font-size: 2em;
    color: var(--neon-cyan);
  }
  
  .una-stat-content h3 {
    color: var(--neon-cyan);
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  
  .una-stat-content p {
    color: var(--neon-green);
    font-size: 1.8em;
    text-shadow: var(--glow-green);
    margin: 5px 0;
  }
  
  .una-stat-content span {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9em;
  }
  
  .una-alerts-section {
    margin-bottom: 30px;
  }
  
  .una-alert {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .una-alert.out-of-stock {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
    color: #ff3e3e;
  }
  
  .una-alert.low-stock {
    background: rgba(255, 165, 0, 0.1);
    border: 1px solid rgba(255, 165, 0, 0.2);
    color: #ffa500;
  }
  
  .una-charts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .una-chart-card {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    padding: 20px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    height: 300px;
  }
  
  .una-chart-card h3 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    margin-bottom: 15px;
    font-size: 1.2em;
  }
  
  .una-performance-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .una-performance-card {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    padding: 20px;
    border: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-performance-card h3 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .una-performance-card ul {
    list-style: none;
    padding: 0;
  }
  
  .una-performance-card li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 255, 157, 0.1);
    color: #fff;
    border-radius: 8px;
  }
  
  .una-rank {
    color: var(--neon-green);
    text-shadow: var(--glow-green);
    margin-right: 10px;
    font-weight: 500;
  }
  
  .una-product-title {
    flex: 1;
  }
  
  .una-sold-count {
    color: var(--neon-cyan);
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .una-charts-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .una-attention-section {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-section-title {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    font-size: 1.5em;
  }
  
  .una-attention-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
  }
  
  .una-attention-card {
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    padding: 20px;
    border: 1px solid rgba(255, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .una-attention-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
  }
  
  .una-attention-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 15px;
  }
  
  .una-attention-header h3 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 1.2em;
    margin: 0;
  }
  
  .una-attention-type {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-green);
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 0.8em;
    text-transform: uppercase;
  }
  
  .una-reasons-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 15px;
  }
  
  .una-reason-tag {
    background: rgba(255, 0, 0, 0.1);
    color: #ff3e3e;
    padding: 4px 12px;
    border-radius: 8px;
    font-size: 0.8em;
    text-transform: uppercase;
  }
  
  .una-reason-tag.out_of_stock { background: rgba(255, 0, 0, 0.1); color: #ff3e3e; }
  .una-reason-tag.low_stock { background: rgba(255, 165, 0, 0.1); color: #ffa500; }
  .una-reason-tag.overstock { background: rgba(0, 255, 255, 0.1); color: var(--neon-cyan); }
  .una-reason-tag.low_sales_velocity { background: rgba(255, 0, 255, 0.1); color: var(--neon-magenta); }
  
  .una-metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 15px;
    margin: 15px 0;
  }
  
  .una-metric {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9em;
  }
  
  .una-metric svg {
    color: var(--neon-cyan);
  }
  
  .una-recommendations {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 0, 0, 0.1);
  }
  
  .una-recommendations h4 {
    color: var(--neon-cyan);
    margin-bottom: 10px;
    font-size: 1em;
  }
  
  .una-recommendations ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .una-recommendations li {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9em;
    padding: 5px 0;
    position: relative;
    padding-left: 15px;
  }
  
  .una-recommendations li:before {
    content: "•";
    color: var(--neon-red);
    position: absolute;
    left: 0;
  }
  
  .una-clickable-item {
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .una-performance-card .una-clickable-item:hover {
    background: rgba(0, 255, 157, 0.1);
    padding-left: 15px;
  }
  
  .una-attention-card.una-clickable-item {
    cursor: pointer;
  }
  
  .una-attention-card.una-clickable-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.3);
  }
  
  .una-stats-loading-screen {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-card);
    border-radius: 20px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    padding: 25px;
    margin: 20px auto;
    max-width: 1400px;
  }
  
  .una-loading-content {
    text-align: center;
    padding: 40px;
    width: 100%;
    max-width: 600px;
  }
  
  .una-loading-icon {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto 30px;
  }
  
  .una-loading-icon svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .una-loading-icon .icon-primary {
    color: var(--neon-cyan);
    animation: pulse 2s infinite;
    filter: drop-shadow(0 0 10px var(--neon-cyan));
  }
  
  .una-loading-icon .icon-secondary {
    color: var(--neon-green);
    animation: pulse 2s infinite 1s;
    filter: drop-shadow(0 0 10px var(--neon-green));
  }
  
  .una-loading-content h2 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 2em;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .una-loading-progress {
    width: 100%;
    height: 4px;
    background: rgba(0, 255, 157, 0.1);
    border-radius: 2px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;
  }
  
  .una-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    background: var(--neon-cyan);
    border-radius: 2px;
    animation: progress 3s infinite;
  }
  
  .una-progress-glow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
    animation: glow 1.5s infinite;
  }
  
  .una-loading-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }
  
  .una-metric-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    background: rgba(13, 14, 26, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 157, 0.1);
    color: rgba(255, 255, 255, 0.8);
    animation: fadeIn 0.5s ease-out forwards;
    opacity: 0;
  }
  
  .una-metric-item svg {
    color: var(--neon-cyan);
    font-size: 1.2em;
  }
  
  .una-metric-item:nth-child(1) { animation-delay: 0.2s; }
  .una-metric-item:nth-child(2) { animation-delay: 0.4s; }
  .una-metric-item:nth-child(3) { animation-delay: 0.6s; }
  .una-metric-item:nth-child(4) { animation-delay: 0.8s; }
  
  @keyframes pulse {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.1); opacity: 0.7; }
    100% { transform: scale(1); opacity: 1; }
  }
  
  @keyframes progress {
    0% { left: -30%; }
    100% { left: 100%; }
  }
  
  @keyframes glow {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  @keyframes fadeIn {
    from { 
      opacity: 0;
      transform: translateY(10px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }