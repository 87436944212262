/* src/components/KnowledgeBoard.css */

.knowledge-board {
  padding: 30px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.knowledge-board::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, transparent 0%, rgba(26, 26, 46, 0.8) 100%);
  pointer-events: none;
}

/* Header Section */
.knowledge-board-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
}

/* Action Circles */
.action-circles {
  position: fixed;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
}

.action-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(26, 26, 46, 0.9);
  border: 2px solid var(--neon-cyan);
  color: var(--neon-cyan);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 24px;
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(5px);
}

.action-circle:hover {
  transform: scale(1.1);
  box-shadow: 0 0 30px var(--neon-cyan);
}

/* Knowledge Graph */
.knowledge-graph {
  position: relative;
  height: calc(100vh - 200px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.central-node {
  position: absolute;
  width: 120px;
  height: 120px;
  background: rgba(26, 26, 46, 0.9);
  border: 2px solid var(--neon-green);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: var(--glow-green);
  animation: pulse 2s infinite;
}

.central-node .ai-icon {
  font-size: 40px;
  color: var(--neon-green);
  margin-bottom: 5px;
  filter: drop-shadow(0 0 10px var(--neon-green));
}

.central-node span {
  font-size: 12px;
  color: var(--neon-green);
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.knowledge-nodes {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center;
  animation: rotateNodes 120s linear infinite;
}

.knowledge-node {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(calc(var(--angle))) translateX(var(--distance)) rotate(calc(-1 * var(--angle)));
}

.node-content {
  width: 180px;
  background: rgba(26, 26, 46, 0.9);
  border: 1px solid var(--neon-cyan);
  border-radius: 15px;
  padding: 15px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  position: relative;
  box-shadow: var(--glow-cyan);
}

.node-content::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  width: calc(var(--distance) - 190px);
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--neon-cyan));
  transform-origin: right;
  transform: translateY(-50%);
  opacity: 0.5;
}

.node-content:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px var(--neon-cyan);
}

.node-icon {
  color: var(--neon-cyan);
  font-size: 20px;
  margin-bottom: 10px;
}

.node-title {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.node-title:hover {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.node-url {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--neon-magenta);
  font-size: 14px;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.node-url:hover {
  opacity: 1;
  transform: scale(1.1);
}

.node-delete {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: var(--neon-magenta);
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.6;
}

.node-delete:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* Upload Status Floating */
.upload-status-floating {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status-item {
  background: rgba(26, 26, 46, 0.9);
  border: 1px solid var(--neon-green);
  border-radius: 12px;
  padding: 15px;
  backdrop-filter: blur(5px);
  animation: slideIn 0.3s ease;
  box-shadow: var(--glow-green);
}

/* Animations */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes rotateNodes {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .knowledge-graph {
    height: calc(100vh - 100px);
  }
  
  .action-circles {
    position: fixed;
    bottom: 20px;
    right: 20px;
    top: auto;
    transform: none;
    flex-direction: row;
  }
  
  .node-content {
    width: 150px;
  }
}
