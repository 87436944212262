/* WhatsAppIntegration.css */

.whatsapp-integration {
    text-align: center;
    padding: 20px;
  }
  
  .whatsapp-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #25D366;
    margin-bottom: 20px;
  }
  
  .whatsapp-icon {
    margin-right: 10px;
    font-size: 30px;
  }
  
  .whatsapp-steps {
    text-align: left;
    margin-bottom: 30px;
  }
  
  .whatsapp-steps p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .whatsapp-ready {
    font-weight: bold;
    color: #25D366;
  }
  
  .whatsapp-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #128C7E;
  }
  
  .whatsapp-button svg {
    margin-right: 8px;
    font-size: 20px;
  }