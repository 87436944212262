.form-result-container {
  padding: 30px;
  background: var(--gradient-bg);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* Cyberpunk Grid Effect */
.form-result-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%),
    linear-gradient(90deg, transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%);
  background-size: 30px 30px;
  opacity: 0.1;
  pointer-events: none;
}

/* Results Header */
.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: var(--glow-cyan);
}

.form-title {
  color: var(--neon-cyan);
  font-size: 1.8em;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-green);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: var(--glow-green);
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(0, 255, 157, 0.3);
}

.stat-label {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  opacity: 0.8;
}

/* Results Table */
.results-table-container {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 15px;
  padding: 20px;
  overflow-x: auto;
  backdrop-filter: blur(10px);
  box-shadow: var(--glow-cyan);
}

.results-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
}

.results-table th {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-weight: 500;
}

.results-table td {
  padding: 15px;
  color: var(--text-color);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.results-table tr {
  transition: all 0.3s ease;
}

.results-table tr:hover {
  background: rgba(0, 255, 255, 0.05);
}

/* Loading State */
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px;
}

.loading-spinner {
  width: 60px;
  height: 60px;
  border: 3px solid var(--neon-cyan);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: var(--glow-cyan);
}

.loading-text {
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
}

/* Error State */
.error {
  background: rgba(255, 0, 85, 0.1);
  border: 1px solid var(--neon-magenta);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  color: var(--neon-magenta);
  text-shadow: var(--glow-magenta);
  margin: 20px 0;
  animation: errorPulse 2s infinite;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  padding: 20px;
}

.page-button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: 8px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-button:hover:not(:disabled) {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
  transform: translateY(-2px);
}

.page-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-button.active {
  background: rgba(0, 255, 255, 0.2);
  box-shadow: var(--glow-cyan);
}

/* Animations */
@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes errorPulse {
  0% { box-shadow: 0 0 0 0 rgba(255, 0, 85, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(255, 0, 85, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 0, 85, 0); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr 1fr;
  }
  
  .results-table-container {
    margin: 0 -20px;
    border-radius: 0;
  }
  
  .form-title {
    font-size: 1.4em;
  }
}
