.instagram-account-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  max-width: 500px;
  margin: 0 auto;
}

.instagram-account-selection h2 {
  margin-bottom: 20px;
}

.instagram-account-selection ul {
  list-style-type: none; /* Remove the dots before list items */
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  width: 100%;
}

.instagram-account-selection li {
  display: flex;
  justify-content: center;
}

.instagram-account-selection button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s;
}

.instagram-account-selection button:hover {
  background-color: #0056b3;
}
