.employee-management-container {
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  min-height: 100vh;
  padding: 30px;
  color: #fff;
}

.employee-dashboard-card {
  border-radius: 20px;
  padding: 30px;
}

.employee-section-title {
  font-size: 2.2em;
  font-weight: 600;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
}

.employee-section-icon {
  color: #00ff9d;
  filter: drop-shadow(0 0 8px rgba(0, 255, 157, 0.4));
}

.employee-add-button {
  background: transparent;
  border: 1px solid #00ff9d;
  color: #00ff9d;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.1);
}

.employee-add-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: 0 0 30px rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
}

.employee-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.employee-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 25px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.employee-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.employee-name {
  font-size: 1.3em;
  font-weight: 500;
  color: #00ff9d;
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.4);
  margin-bottom: 5px;
}

.employee-email {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9em;
}

.employee-action-button {
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.employee-action-button.edit {
  color: #00ffff;
}

.employee-action-button.delete {
  color: #ff00ff;
}

.employee-action-button:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.employee-description {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 20px;
  min-height: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-stats {
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
}

.stats-row {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat-label {
  color: #00ffff;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
}

.stat-value {
  font-size: 1.8em;
  font-weight: 600;
  color: #00ff9d;
  text-shadow: 0 0 15px rgba(0, 255, 157, 0.5);
}

.tags-stats {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 8px;
}

.tag {
  background: rgba(0, 255, 255, 0.1);
  border: 1px solid rgba(0, 255, 255, 0.2);
  color: #00ffff;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.8em;
  text-shadow: 0 0 8px rgba(0, 255, 255, 0.4);
}

/* Modal styles */
.employee-create-modal {
  background: rgba(13, 14, 26, 0.95);
  backdrop-filter: blur(20px);
}

.employee-modal-content {
  background: rgba(26, 27, 38, 0.95);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 20px;
  padding: 30px;
  color: #fff;
  box-shadow: 0 0 40px rgba(0, 255, 157, 0.1);
}

.employee-form-group input,
.employee-form-group textarea {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 157, 0.2);
  color: #fff;
  border-radius: 10px;
  padding: 12px;
}

.employee-form-group input:focus,
.employee-form-group textarea:focus {
  border-color: #00ff9d;
  box-shadow: 0 0 15px rgba(0, 255, 157, 0.2);
}

.employee-submit-button {
  background: linear-gradient(45deg, #00ff9d, #00ffff);
  border: none;
  color: #1a1a2e;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.employee-cancel-button {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 12px 24px;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Remove any inherited opacity */
.employee-stats *,
.stat-label,
.stat-value {
  opacity: 1 !important;
}

.una-employee-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-loading-content {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

.una-loading-icons {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.una-loading-icons svg {
  position: absolute;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 30px;
  color: var(--neon-cyan);
  animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green);
  animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta);
  animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.una-loading-progress {
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  animation: progress 2s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
  animation: glow 1.5s infinite;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-metric-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes floatIcon {
  0%, 100% { 
    transform: translateY(0) scale(1); 
    opacity: 1;
  }
  50% { 
    transform: translateY(-10px) scale(1.1); 
    opacity: 0.8;
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}