/* Root variables for our cyberpunk palette */
:root {
    --primary-color: var(--neon-cyan);
    --primary-light: var(--neon-cyan);
    --gradient-bg: linear-gradient(135deg, rgba(26, 26, 46, 0.8), rgba(26, 26, 46, 0.4));
    --gradient-card: linear-gradient(135deg, rgba(26, 26, 46, 0.8), rgba(26, 26, 46, 0.4));
    --border-color: var(--neon-cyan);
    --glow-green: 0 0 10px rgba(0, 255, 157, 0.5);
    --text-color: #fff;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --neon-cyan: #00ffff;
    --neon-magenta: #ff00ff;
    --neon-green: #00ff9d;
    --glow-effect: var(--glow-cyan);
  }
  
  /* Global styles */
  body {
    background: var(--gradient-bg);
    color: var(--text-color);
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
}

/* Main Container */
.live-session-container {
    max-width: 1600px;  /* Increased to accommodate side-by-side layout */
    margin: 40px auto;
    padding: 20px;
    background: var(--gradient-card);
    border-radius: 20px;
    border: 1px solid var(--border-color);
    box-shadow: var(--glow-green);
}

/* Header Section */
.live-session-header {
    text-align: center;
    margin-bottom: 30px;
}

.live-session-title {
    font-size: 3rem;
    margin: 0;
    text-shadow: var(--glow-cyan);
    color: var(--neon-cyan);
  }
  
  .header-actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.complete-orders-btn {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-cyan);
    border: 1px solid var(--neon-cyan);
    padding: 12px 24px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    text-shadow: var(--glow-cyan);
    box-shadow: var(--glow-cyan);
  }
  
  .complete-orders-btn:hover:not(:disabled) {
    background: rgba(0, 255, 255, 0.2);
    box-shadow: var(--glow-cyan);
  }
  
  .complete-orders-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.completion-status {
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 0.9rem;
    margin-top: 10px;
}

.completion-status.success {
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-cyan);
    border: 1px solid var(--neon-cyan);
    box-shadow: var(--glow-cyan);
}

.completion-status.error {
    background: rgba(255, 0, 255, 0.1);
    color: var(--neon-magenta);
    border: 1px solid var(--neon-magenta);
    box-shadow: var(--glow-magenta);
}

/* Session Summary Section */
.session-summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
    padding: 10px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.summary-item {
    flex: 1 1 200px;
    margin: 10px;
    text-align: center;
}

.summary-item h3 {
    margin: 0 0 5px;
    font-size: 1.2rem;
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
}

/* New Grid Layout */
.live-list-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
}

.live-list-grid-column {
    background: rgba(26, 26, 46, 0.6);
    border-radius: 15px;
    padding: 20px;
    border: 1px solid var(--neon-cyan);
    box-shadow: var(--glow-cyan);
  }
  
  .live-list-grid-column h2 {
    color: var(--neon-cyan);
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--neon-cyan);
    text-shadow: var(--glow-cyan);
  }
  
  .orders-scroll-container,
  .customers-scroll-container {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    padding-right: 10px;
}

/* Update existing scrollbar styles to use new container classes */
.orders-scroll-container::-webkit-scrollbar,
.customers-scroll-container::-webkit-scrollbar {
    width: 8px;
}

.orders-scroll-container::-webkit-scrollbar-track,
.customers-scroll-container::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.8);
    border-radius: 4px;
  }
  
  .orders-scroll-container::-webkit-scrollbar-thumb,
  .customers-scroll-container::-webkit-scrollbar-thumb {
    background: var(--neon-cyan);
    border-radius: 4px;
}

/* Orders Section */
.orders-section {
    flex: 1;
    margin-bottom: 0;
    max-width: 50%;
}

.orders-section h2 {
    border-bottom: 2px solid var(--neon-cyan);
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.orders-list {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    padding-right: 10px;
}

.order-card {
    background: rgba(26, 26, 46, 0.8);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--neon-green);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.order-card:hover {
    transform: translateY(-3px);
    box-shadow: var(--glow-cyan);
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--neon-cyan);
  }
  
  .order-info p {
    margin: 5px 0;
}

.order-items {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.order-item {
    display: flex;
    align-items: center;
    background: rgba(26, 26, 46, 0.6);
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
}

.item-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

/* Customers Section */
.customers-section {
    flex: 1;
    margin-bottom: 0;
    max-width: 50%;
}

.customers-section h2 {
    border-bottom: 2px solid var(--neon-cyan);
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.customers-list {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    padding-right: 10px;
}

.customer-card {
    background: rgba(26, 26, 46, 0.8);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--neon-cyan);
    flex: 1 1 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.customer-card:hover {
    transform: translateY(-3px);
    box-shadow: var(--glow-cyan);
  }
  
  .customer-card h3 {
    margin-top: 0;
    color: var(--neon-cyan);
}

/* Loading and Error States */
.loading-message,
.error-message {
    text-align: center;
    margin: 50px 0;
    font-size: 1.5rem;
}

/* Search bar styling */
.search-container {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0;
}

.search-input {
    height: 44px;
    box-sizing: border-box;
    width: 100%;
    padding: 12px 20px;
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid var(--neon-cyan);
    border-radius: 12px;
    color: var(--neon-cyan);
    font-size: 0.9rem;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    box-shadow: var(--glow-cyan);
    margin-bottom: 30px;
}

.search-input:focus {
    outline: none;
    border-color: var(--neon-magenta);
    box-shadow: var(--glow-magenta);
    background: rgba(13, 14, 26, 0.9);
  }
  
  .search-input::placeholder {
    color: rgba(0, 255, 255, 0.5);
  }
  
  /* Complete button styling */
  .complete-order-btn {
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-cyan);
    border: 1px solid var(--neon-cyan);
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.8rem;
    text-shadow: var(--glow-cyan);
    margin-bottom: 30px;
    box-shadow: var(--glow-cyan);
}

.complete-order-btn:hover:not(:disabled) {
    background: rgba(0, 255, 255, 0.2);
    box-shadow: var(--glow-cyan);
}

.complete-order-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.complete-order-btn.completing {
    border-color: var(--neon-magenta);
    color: var(--neon-magenta);
    box-shadow: var(--glow-magenta);
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.7;
        transform: scale(0.98);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.click-collect-header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.order-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.order-status {
    text-align: center;
    padding: 8px;
    border-radius: 8px;
    font-size: 0.9em;
}

.order-status.success {
    background: rgba(0, 255, 255, 0.1);
    color: var(--neon-cyan);
    border: 1px solid var(--neon-cyan);
    box-shadow: var(--glow-cyan);
}

.order-status.error {
    background: rgba(255, 0, 255, 0.1);
    color: var(--neon-magenta);
    border: 1px solid var(--neon-magenta);
    box-shadow: var(--glow-magenta);
}
  