.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #000000, #2c003e);
  position: relative;
  overflow: hidden;
}

/* Cyberpunk Grid Effect */
.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%),
    linear-gradient(90deg, transparent 0%, transparent 95%, var(--neon-cyan) 95%, transparent 100%);
  background-size: 30px 30px;
  opacity: 0.05;
  animation: gridMove 20s linear infinite;
}

/* Floating Particles */
.login-container::after {
  content: '';
  position: absolute;
  width: 200vw;
  height: 200vh;
  top: -50%;
  left: -50%;
  background-image: 
    radial-gradient(circle, var(--neon-cyan) 1px, transparent 1px),
    radial-gradient(circle, var(--neon-magenta) 1px, transparent 1px);
  background-size: 40px 40px;
  animation: particleFloat 15s linear infinite;
  opacity: 0.15;
}

.login-form {
  width: 400px;
  max-width: 90vw;
  padding: 40px 30px;
  margin: 20px;
  background: rgba(26, 26, 46, 0.8);
  color: var(--text-color);
  border-radius: 20px;
  border: 1px solid var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 1;
  animation: formAppear 0.5s ease-out;
  box-sizing: border-box;
}

/* Glowing Border Effect */
.login-form::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, 
    var(--neon-cyan),
    var(--neon-magenta),
    var(--neon-purple),
    var(--neon-cyan)
  );
  border-radius: 20px;
  z-index: -1;
  animation: borderGlow 3s linear infinite;
  opacity: 0.5;
}

.login-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.login-header .logo {
  max-width: 80%;
  height: auto;
  filter: drop-shadow(0 0 10px var(--neon-cyan));
  animation: logoGlow 2s ease-in-out infinite alternate;
}

.login-form h2 {
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: var(--glow-cyan);
  position: relative;
}

.form-group {
  margin-bottom: 25px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--neon-magenta);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-magenta);
}

.form-group input {
  width: 100%;
  padding: 12px 20px;
  font-size: 16px;
  background: rgba(26, 26, 46, 0.6);
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  color: var(--text-color);
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  background: rgba(26, 26, 46, 0.8);
}

.login-button {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--text-color);
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-purple);
  border-radius: 12px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.login-button:hover {
  background: rgba(98, 0, 234, 0.1);
  box-shadow: var(--glow-purple);
  transform: translateY(-2px);
}

.login-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.login-button:hover::before {
  left: 100%;
}

.login-form a {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: var(--neon-cyan);
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.login-form a:hover {
  color: var(--neon-magenta);
  text-shadow: var(--glow-magenta);
}

/* Animations */
@keyframes formAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes borderGlow {
  0% { opacity: 0.5; }
  50% { opacity: 0.8; }
  100% { opacity: 0.5; }
}

@keyframes logoGlow {
  from { filter: drop-shadow(0 0 10px var(--neon-cyan)); }
  to { filter: drop-shadow(0 0 20px var(--neon-magenta)); }
}

@keyframes gridMove {
  0% { transform: translateY(0); }
  100% { transform: translateY(30px); }
}

@keyframes particleFloat {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login-page-container {
  position: relative;
  min-height: 100vh; /* Ensures the container takes up at least the full viewport height */
}

.language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; /* Ensures the language switcher is above other elements */
}
