.una-products-container {
    padding: 25px;
    margin-top: 20px;
  }
  
  .una-products-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  }
  
  .una-products-title {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--neon-cyan);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-cyan);
  }
  
  .una-optimize-button {
    background: transparent;
    border: 1px solid var(--neon-green);
    color: var(--neon-green);
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .una-optimize-button:hover:not(:disabled) {
    background: rgba(0, 255, 157, 0.1);
    box-shadow: var(--glow-green);
  }
  
  .una-optimize-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: rgba(0, 255, 157, 0.3);
  }
  
  .una-products-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .una-products-table th {
    text-align: left;
    padding: 16px;
    color: var(--neon-cyan);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    text-shadow: var(--glow-cyan);
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  }
  
  .una-products-table td {
    padding: 16px;
    background: rgba(13, 14, 26, 0.7);
    color: #fff;
    transition: all 0.3s ease;
    border-top: 1px solid rgba(0, 255, 157, 0.1);
    border-bottom: 1px solid rgba(0, 255, 157, 0.1);
  }
  
  .una-products-table tr:hover td {
    background: rgba(0, 255, 157, 0.05);
    box-shadow: inset 0 0 20px rgba(0, 255, 157, 0.1);
    cursor: pointer;
  }
  
  .una-product-checkbox {
    width: 18px;
    height: 18px;
    accent-color: var(--neon-green);
  }
  
  .una-product-image-list {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid rgba(0, 255, 157, 0.2);
  }
  
  .una-product-variants {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .una-product-variant-item {
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid rgba(0, 255, 255, 0.2);
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 0.8em;
    color: var(--neon-cyan);
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .una-variant-warning {
    color: #ff3e3e;
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
  
  .una-variant-warning svg {
    filter: drop-shadow(0 0 2px rgba(255, 62, 62, 0.5));
  }
  
  .una-variant-count {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-green);
    padding: 1px 4px;
    border-radius: 4px;
    font-size: 0.9em;
    margin-left: 4px;
  }
  
  .una-product-tags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
  
  .una-product-tag {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-magenta);
    border: 1px solid rgba(255, 0, 255, 0.2);
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .una-product-error {
    color: var(--neon-magenta);
    text-shadow: var(--glow-magenta);
    text-align: center;
    padding: 20px;
    background: rgba(255, 0, 255, 0.1);
    border-radius: 12px;
    border: 1px solid rgba(255, 0, 255, 0.2);
  }
  
  .una-product-empty {
    text-align: center;
    padding: 20px;
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    background: rgba(0, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 255, 0.1);
  }
  
  .una-products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
  }
  
  .una-product-card {
    position: relative;
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 12px;
    transition: all 0.3s ease;
  }
  
  .una-product-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 255, 157, 0.1);
    border-color: rgba(0, 255, 157, 0.3);
  }
  
  .una-product-checkbox-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  
  .una-product-content {
    cursor: pointer;
    padding: 20px;
  }
  
  .una-product-image-wrapper {
    width: 100%;
    height: 200px;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 255, 157, 0.2);
  }
  
  .una-product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .una-product-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .una-product-title {
    color: #fff;
    font-size: 1.2em;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .una-product-info {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .una-product-type,
  .una-product-vendor,
  .una-product-status {
    background: rgba(0, 255, 157, 0.1);
    color: var(--neon-cyan);
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 0.9em;
  }
  
  .una-products-loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    color: rgba(255, 255, 255, 0.6);
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 12px;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 255, 255, 0.1);
    border-radius: 50%;
    border-top-color: var(--neon-cyan);
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .una-products-loading-state p {
    font-size: 1.1rem;
    margin: 0;
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
  }
  
  .una-products-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    color: rgba(255, 255, 255, 0.6);
    background: rgba(13, 14, 26, 0.7);
    border: 1px solid rgba(0, 255, 157, 0.1);
    border-radius: 12px;
  }
  
  .una-products-empty-state .empty-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: var(--neon-cyan);
    opacity: 0.5;
  }
  
  .una-products-empty-state p {
    font-size: 1.1rem;
    margin: 0;
  }

  .una-product-loading-screen {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    border-radius: 20px;
    padding: 25px;
    margin: 20px auto;
    max-width: 1400px;
  }

  .una-loading-content {
    text-align: center;
    padding: 40px;
    width: 100%;
    max-width: 600px;
  }

  .una-loading-icons {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto 30px;
  }

  .una-loading-icons svg {
    position: absolute;
    width: 60px;
    height: 60px;
    filter: drop-shadow(0 0 10px currentColor);
  }

  .una-loading-icons .icon-primary {
    top: 0;
    left: 30px;
    color: var(--neon-cyan);
    animation: floatIcon 3s infinite;
  }

  .una-loading-icons .icon-secondary {
    bottom: 0;
    left: 0;
    color: var(--neon-green);
    animation: floatIcon 3s infinite 1s;
  }

  .una-loading-icons .icon-tertiary {
    bottom: 0;
    right: 0;
    color: var(--neon-magenta);
    animation: floatIcon 3s infinite 2s;
  }

  .una-loading-content h2 {
    color: var(--neon-cyan);
    text-shadow: var(--glow-cyan);
    font-size: 2em;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .una-loading-progress {
    width: 100%;
    height: 4px;
    background: rgba(0, 255, 157, 0.1);
    border-radius: 2px;
    margin: 30px 0;
    position: relative;
    overflow: hidden;
  }

  .una-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    background: var(--neon-cyan);
    animation: progress 2s infinite;
  }

  .una-progress-glow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, var(--neon-cyan), transparent);
    animation: glow 1.5s infinite;
  }

  @keyframes floatIcon {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes glow {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }