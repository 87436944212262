.info-panel {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: rgba(26, 26, 46, 0.8);
    overflow-y: auto;
    border-left: 1px solid var(--neon-magenta);
    box-shadow: var(--glow-magenta);
    backdrop-filter: blur(10px);
    position: relative;
    box-sizing: border-box;
}

.info-panel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        linear-gradient(transparent 0%, transparent 95%, var(--neon-magenta) 95%, transparent 100%),
        linear-gradient(90deg, transparent 0%, transparent 95%, var(--neon-magenta) 95%, transparent 100%);
    background-size: 30px 30px;
    opacity: 0.05;
    pointer-events: none;
}

.info-section {
    margin-bottom: 25px;
    padding: 15px;
    transition: all 0.3s ease;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.info-section:hover {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.2);
}

.info-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
        transparent 0%,
        rgba(0, 255, 255, 0.1) 45%,
        rgba(0, 255, 255, 0.2) 50%,
        rgba(0, 255, 255, 0.1) 55%,
        transparent 100%
    );
    transition: left 0.5s ease;
}

.info-section:hover::before {
    left: 100%;
}

.info-section h2 {
    font-size: 16px;
    font-weight: 600;
    color: var(--neon-cyan);
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-cyan);
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
    padding-bottom: 10px;
}

.info-section h2 svg {
    color: var(--neon-cyan);
    filter: drop-shadow(0 0 5px var(--neon-cyan));
}

.client-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    width: 100%;
    box-sizing: border-box;
}

.info-item {
    font-size: 13px;
    display: flex;
    gap: 10px;
    padding: 8px;
    background: rgba(26, 26, 46, 0.4);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.info-item:hover {
    background: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
}

.info-label {
    font-weight: 600;
    color: var(--neon-magenta);
    white-space: nowrap;
    text-shadow: var(--glow-magenta);
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
}

.info-value {
    color: var(--text-color);
    word-break: break-word;
}

.order-details-container {
    font-size: 13px;
    width: 100%;
    box-sizing: border-box;
}

.order-detail-item {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 8px;
    background: rgba(26, 26, 46, 0.4);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 255, 0.1);
    transition: all 0.3s ease;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.order-detail-item:hover {
    background: rgba(0, 255, 255, 0.05);
    border-color: var(--neon-cyan);
    box-shadow: var(--glow-cyan);
}

.detail-label {
    font-weight: 600;
    color: var(--neon-magenta);
    text-shadow: var(--glow-magenta);
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
}

.detail-value {
    color: var(--text-color);
    text-align: right;
    word-break: break-word;
    max-width: 60%;
}

.order-detail-header {
    font-weight: 600;
    color: var(--neon-green);
    margin-top: 15px;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 255, 157, 0.2);
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: var(--glow-green);
}

.summary-content {
    font-size: 13px;
    color: var(--text-color);
    line-height: 1.6;
    padding: 12px;
    background: rgba(26, 26, 46, 0.4);
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 255, 0.1);
}

/* Cyberpunk Scrollbar */
.info-panel::-webkit-scrollbar {
    width: 6px;
}

.info-panel::-webkit-scrollbar-track {
    background: rgba(26, 26, 46, 0.4);
}

.info-panel::-webkit-scrollbar-thumb {
    background: var(--neon-cyan);
    border-radius: 3px;
    box-shadow: var(--glow-cyan);
}

.info-panel::-webkit-scrollbar-thumb:hover {
    background: var(--neon-magenta);
    box-shadow: var(--glow-magenta);
}