.function-forge {
  padding: 30px;
  background: var(--gradient-bg);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.function-forge::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    repeating-linear-gradient(
      90deg,
      transparent 0%,
      transparent 98%,
      rgba(0, 255, 255, 0.1) 98%,
      rgba(0, 255, 255, 0.1) 100%
    ),
    repeating-linear-gradient(
      0deg,
      transparent 0%,
      transparent 98%,
      rgba(0, 255, 255, 0.1) 98%,
      rgba(0, 255, 255, 0.1) 100%
    );
  background-size: 50px 50px;
  pointer-events: none;
  opacity: 0.5;
}

.forge-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 30px;
  position: relative;
  z-index: 1;
}

/* Functions Panel */
.functions-panel {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 20px;
  padding: 25px;
  backdrop-filter: blur(10px);
  height: calc(100vh - 100px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.forge-title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--neon-cyan);
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
}

.forge-icon {
  font-size: 1.2em;
  filter: drop-shadow(0 0 5px var(--neon-cyan));
}

.forge-icon.pulse {
  animation: iconPulse 2s infinite;
}

.functions-list {
  overflow-y: auto;
  padding-right: 10px;
  flex: 1;
}

/* Function Cards */
.function-card {
  background: rgba(26, 26, 46, 0.6);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.function-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(0, 255, 255, 0.1) 45%,
    rgba(0, 255, 255, 0.2) 50%,
    rgba(0, 255, 255, 0.1) 55%,
    transparent 100%
  );
  transform: translateX(-100%);
  transition: transform 0.5s ease;
}

.function-card:hover::before {
  transform: translateX(100%);
}

.function-card:hover {
  transform: translateY(-3px);
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.function-card.active {
  border-color: var(--neon-green);
  box-shadow: var(--glow-green);
  background: rgba(0, 255, 157, 0.1);
}

.function-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}

.function-icon {
  color: var(--neon-cyan);
  font-size: 20px;
  filter: drop-shadow(0 0 5px var(--neon-cyan));
}

.function-name {
  color: var(--neon-cyan) !important;
  font-size: 1.2em !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
}

.function-description {
  color: var(--text-color);
  opacity: 0.9;
  font-size: 0.95em;
  line-height: 1.4;
  margin: 12px 0;
  padding-left: 10px;
  border-left: 2px solid var(--neon-cyan);
  transition: all 0.3s ease;
}

.function-card:hover .function-description {
  border-left-color: var(--neon-green);
}

.function-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.function-type {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--neon-magenta);
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Editor Panel */
.editor-panel {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 20px;
  padding: 25px;
  backdrop-filter: blur(10px);
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.code-section {
  margin: 20px 0;
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 15px;
  overflow: hidden;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: rgba(13, 14, 26, 0.8);
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.code-actions {
  display: flex;
  gap: 10px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid var(--neon-green);
  color: var(--neon-green);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: rgba(0, 255, 157, 0.1);
  box-shadow: var(--glow-green);
  transform: translateY(-2px);
}

/* Test Results */
.test-result {
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
  animation: slideUp 0.3s ease;
}

.test-result.success {
  background: rgba(0, 255, 157, 0.1);
  border: 1px solid var(--neon-green);
}

.test-result.error {
  background: rgba(255, 0, 85, 0.1);
  border: 1px solid var(--neon-magenta);
}

.result-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.result-output {
  background: rgba(13, 14, 26, 0.8);
  padding: 15px;
  border-radius: 8px;
  font-family: 'Fira Code', monospace;
  font-size: 0.9em;
  white-space: pre-wrap;
}

@keyframes iconPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom Scrollbar */
.functions-list::-webkit-scrollbar,
.editor-panel::-webkit-scrollbar {
  width: 6px;
}

.functions-list::-webkit-scrollbar-track,
.editor-panel::-webkit-scrollbar-track {
  background: rgba(26, 26, 46, 0.7);
  border-radius: 3px;
}

.functions-list::-webkit-scrollbar-thumb,
.editor-panel::-webkit-scrollbar-thumb {
  background: var(--neon-cyan);
  border-radius: 3px;
}

.functions-list::-webkit-scrollbar-thumb:hover,
.editor-panel::-webkit-scrollbar-thumb:hover {
  background: var(--neon-green);
  box-shadow: var(--glow-green);
}

/* Code Editor Customization */
.code-editor {
  position: relative;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
}

/* Monaco Editor Container */
.code-editor .monaco-editor {
  padding: 10px 0;
  background: rgba(13, 14, 26, 0.95) !important;
}

/* Editor Content */
.code-editor .monaco-editor .margin,
.code-editor .monaco-editor .monaco-editor-background {
  background: rgba(13, 14, 26, 0.95) !important;
}

/* Line Numbers */
.code-editor .monaco-editor .line-numbers {
  color: var(--neon-cyan) !important;
  opacity: 0.6;
  text-shadow: var(--glow-cyan);
}

/* Current Line Highlight */
.code-editor .monaco-editor .current-line {
  border: none !important;
  background: rgba(0, 255, 255, 0.05) !important;
}

/* Selection Background */
.code-editor .monaco-editor .selected-text {
  background: rgba(0, 255, 255, 0.2) !important;
}

/* Cursor */
.code-editor .monaco-editor .cursor {
  background: var(--neon-cyan) !important;
  box-shadow: 0 0 8px var(--neon-cyan) !important;
}

/* Syntax Highlighting */
.code-editor .monaco-editor .mtk1 { color: #fff; } /* Default text */
.code-editor .monaco-editor .mtk2 { color: #00ffff; } /* Keywords */
.code-editor .monaco-editor .mtk3 { color: #ff00ff; } /* Strings */
.code-editor .monaco-editor .mtk4 { color: #00ff9d; } /* Numbers */
.code-editor .monaco-editor .mtk5 { color: #ff9d00; } /* Functions */
.code-editor .monaco-editor .mtk6 { color: #ff0055; } /* Operators */
.code-editor .monaco-editor .mtk7 { color: #00ffff; } /* Variables */
.code-editor .monaco-editor .mtk8 { color: #ffff00; } /* Comments */

/* Scrollbar */
.code-editor .monaco-editor .monaco-scrollable-element .scrollbar {
  background: rgba(26, 26, 46, 0.7) !important;
}

.code-editor .monaco-editor .monaco-scrollable-element .slider {
  background: var(--neon-cyan) !important;
  opacity: 0.7;
  border-radius: 3px;
}

.code-editor .monaco-editor .monaco-scrollable-element .slider:hover {
  background: var(--neon-green) !important;
  opacity: 1;
  box-shadow: var(--glow-green);
}

/* Suggestions Dropdown */
.monaco-list-row {
  background: rgba(13, 14, 26, 0.95) !important;
}

.monaco-list-row:hover {
  background: rgba(0, 255, 255, 0.1) !important;
}

.monaco-list-row.focused {
  background: rgba(0, 255, 255, 0.2) !important;
}

/* Add this to your component where you initialize Monaco Editor */

/* Specific naming for OpenAPI components */
.openapi-schema-title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--neon-cyan);
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: var(--glow-cyan);
}

.openapi-add-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  background: linear-gradient(
    45deg,
    rgba(0, 255, 255, 0.1),
    rgba(0, 255, 157, 0.1)
  );
  border: 1px solid var(--neon-cyan);
  border-radius: 12px;
  color: var(--neon-cyan);
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.openapi-add-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.openapi-add-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
  border-color: var(--neon-green);
  color: var(--neon-green);
}

.openapi-add-button:hover::before {
  left: 100%;
}

.openapi-add-button span {
  font-weight: 500;
}

/* Schemas Grid Layout */
.schemas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  padding: 20px;
}

/* Schema Cards */
.schema-card {
  background: rgba(26, 26, 46, 0.8);
  border: 1px solid var(--neon-cyan);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.schema-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.schema-card:hover::before {
  left: 100%;
}

.schema-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 255, 255, 0.2);
}

/* Add Schema Card */
.schema-card.add-schema {
  border: 2px dashed var(--neon-cyan);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.add-schema .schema-card-content {
  text-align: center;
}

.add-schema .add-icon {
  font-size: 2em;
  color: var(--neon-cyan);
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.schema-card.add-schema:hover {
  border-color: var(--neon-green);
  background: rgba(0, 255, 157, 0.1);
}

.schema-card.add-schema:hover .add-icon {
  color: var(--neon-green);
  transform: rotate(90deg);
}

/* Schema Card Content */
.schema-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.schema-icon {
  color: var(--neon-cyan);
  font-size: 1.5em;
}

.schema-name {
  color: var(--neon-cyan);
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
}

.schema-description {
  color: var(--text-color);
  font-size: 0.9em;
  line-height: 1.4;
  margin: 10px 0;
  opacity: 0.8;
}

.schema-meta {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
  color: var(--neon-magenta);
  font-size: 0.9em;
}

.schema-operations {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Delete Button */
.delete-schema-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  color: var(--neon-magenta);
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease;
}

.schema-card:hover .delete-schema-button {
  opacity: 1;
}

.delete-schema-button:hover {
  color: var(--neon-red);
  transform: scale(1.1);
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.schema-card-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--neon-cyan);
}

.schema-card-content h3 {
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
  text-shadow: var(--glow-cyan);
}

.schema-card-content p {
  color: var(--text-color);
  font-size: 0.9em;
  line-height: 1.5;
  opacity: 0.8;
  margin: 0;
}

.add-schema .schema-card-content {
  text-align: center;
  align-items: center;
}

.add-schema .schema-card-content h3 {
  color: var(--neon-cyan);
  transition: color 0.3s ease;
}

.add-schema:hover .schema-card-content h3 {
  color: var(--neon-green);
  text-shadow: var(--glow-green);
}