.container {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  padding: 30px;
  margin: 20px auto;
  max-width: 1400px;
  position: relative;
  overflow: hidden;
}

/* Cyberpunk grid background effect */
.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(transparent 0%, rgba(0, 255, 157, 0.02) 50%, transparent 100%),
    linear-gradient(90deg, transparent 0%, rgba(0, 255, 157, 0.02) 50%, transparent 100%);
  background-size: 40px 40px;
  animation: gridMove 30s linear infinite;
  z-index: 0;
  opacity: 0.5;
}

@keyframes gridMove {
  0% { background-position: 0 0; }
  100% { background-position: 30px 30px; }
}

.title {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.user-info {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  position: relative;
  z-index: 1;
}

.user-info p {
  color: var(--neon-green);
  margin: 10px 0;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Billing toggle redesign */
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 30px 0;
  position: relative;
  z-index: 1;
}

.billing-toggle span {
  color: var(--neon-cyan);
  font-size: 1.1em;
  text-transform: uppercase;
}

.discount {
  background: rgba(255, 0, 157, 0.1);
  color: var(--neon-magenta) !important;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.9em !important;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(255, 0, 157, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(255, 0, 157, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 0, 157, 0); }
}

/* Plans grid redesign */
.plans-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  position: relative;
  z-index: 1;
  perspective: 1000px;
}

.plan-card {
  background: rgba(13, 14, 26, 0.7);
  border-radius: 15px;
  padding: 30px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  position: relative;
}

.plan-card:hover {
  transform: translateY(-10px) rotateX(5deg);
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

.plan-card h3 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 1.8em;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.plan-description {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
  font-size: 1.1em;
  min-height: 60px;
}

.price {
  color: var(--neon-green);
  text-shadow: var(--glow-green);
  font-size: 2.5em;
  margin: 20px 0;
  font-weight: bold;
}

.price span {
  font-size: 0.4em;
  color: rgba(255, 255, 255, 0.6);
  text-shadow: none;
}

.subscribe-button {
  background: linear-gradient(45deg, var(--neon-cyan), var(--neon-green));
  border: none;
  border-radius: 8px;
  padding: 12px 25px;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin: 20px 0;
  position: relative;
  overflow: hidden;
}

.subscribe-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transform: rotate(45deg);
  animation: buttonGlow 2s linear infinite;
}

@keyframes buttonGlow {
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
}

.subscribe-button:disabled {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.feature-list li {
  color: rgba(255, 255, 255, 0.9);
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1em;
}

.check-icon {
  width: 20px;
  height: 20px;
  color: var(--neon-green);
}

/* Custom plan form overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(13, 14, 26, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.custom-request-form {
  background: var(--bg-card);
  border-radius: 15px;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  border: 1px solid var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  animation: formAppear 0.3s ease-out;
}

@keyframes formAppear {
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.custom-request-form h3 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  margin-bottom: 20px;
  font-size: 1.5em;
}

.custom-request-form textarea {
  width: 100%;
  min-height: 150px;
  background: rgba(13, 14, 26, 0.7);
  border: 1px solid rgba(0, 255, 157, 0.1);
  border-radius: 8px;
  padding: 15px;
  color: #fff;
  margin-bottom: 20px;
  resize: vertical;
}

.custom-request-form button {
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

.custom-request-form button[type="submit"] {
  background: linear-gradient(45deg, var(--neon-cyan), var(--neon-green));
  border: none;
  color: #000;
}

.custom-request-form button[type="button"] {
  background: transparent;
  border: 1px solid var(--neon-red);
  color: var(--neon-red);
}

/* Cancel subscription section */
.cancel-subscription {
  margin-top: 40px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.cancel-button {
  background: transparent;
  border: 1px solid var(--neon-red);
  color: var(--neon-red);
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button:hover {
  background: rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .plans-grid {
    grid-template-columns: 1fr;
  }
  
  .billing-toggle {
    flex-direction: column;
    text-align: center;
  }
}