/* TagList.css */

.tag-list {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 10px #2c003e;
  }
  
  .tag-list h3 {
    margin-top: 0;
    color: #333333;
    text-align: center;
  }
  
  .tag-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .tag-list li {
    display: inline-block;
    background: linear-gradient(145deg, #000000, #2a0d64);
    color: white;
    padding: 8px 16px;
    margin: 5px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .tag-list li:hover {
    background: linear-gradient(145deg, #2a0d64, #000000);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
