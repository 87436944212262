/* Supprimez le :root car il est déjà dans TicketTableArchive.css */

.unanimity-archive-wrapper {
    background: var(--bg-dark-base);
    min-height: 100vh;
    padding: 30px;
    color: #fff;
  }
  

.unanimity-archive-header {
  margin-bottom: 30px;
  padding-left: 30px;
}

.unanimity-archive-title {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 2.2em;
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.unanimity-archive-title-icon {
  color: var(--neon-green);
  filter: drop-shadow(var(--glow-green));
}

.unanimity-archive-container {
  background: var(--bg-card);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 25px;
  border: 1px solid rgba(0, 255, 157, 0.1);
}

.unanimity-archive-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--neon-cyan);
  font-size: 16px;
  text-shadow: var(--glow-cyan);
}

/* Table Controls */
.unanimity-archive-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap: 20px;
}

.unanimity-archive-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.unanimity-archive-filter-label {
  color: var(--neon-cyan);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9em;
  text-shadow: var(--glow-cyan);
}

.unanimity-archive-filter-select {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--neon-green);
  color: #fff;
  border-radius: 8px;
  padding: 10px 16px;
  transition: all 0.3s ease;
}

.unanimity-archive-filter-select:focus {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  outline: none;
}

/* Search Section */
.unanimity-archive-search {
  display: flex;
  align-items: center;
  width: 60%;
}

.unanimity-archive-search-input {
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--neon-green);
  color: #fff;
  border-radius: 8px 0 0 8px;
  padding: 10px 16px;
}

.unanimity-archive-search-input:focus {
  border-color: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
  outline: none;
}

.unanimity-archive-search-button {
  background: var(--neon-green);
  color: var(--bg-dark-base);
  border: none;
  border-radius: 0 8px 8px 0;
  padding: 10px 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.unanimity-archive-search-button:hover {
  background: var(--neon-cyan);
  box-shadow: var(--glow-cyan);
}

/* Status Badges */
.unanimity-archive-status {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.unanimity-archive-status-closed {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4d4d;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.unanimity-archive-status-resolved {
  background: rgba(0, 255, 255, 0.1);
  color: var(--neon-cyan);
  border: 1px solid rgba(0, 255, 255, 0.2);
}

.unanimity-archive-status-ai-resolved {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

/* Sentiment Scores */
.unanimity-archive-sentiment {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.unanimity-archive-sentiment-low {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4d4d;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.unanimity-archive-sentiment-medium {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

.unanimity-archive-sentiment-high {
  background: rgba(0, 255, 157, 0.1);
  color: var(--neon-green);
  border: 1px solid rgba(0, 255, 157, 0.2);
}

.unanimity-archive-sentiment-null {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Pagination */
.unanimity-archive-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.unanimity-archive-pagination-button {
  background: transparent;
  border: 1px solid var(--neon-cyan);
  color: var(--neon-cyan);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.unanimity-archive-pagination-button:hover:not(:disabled) {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: var(--glow-cyan);
}

.unanimity-archive-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.unanimity-archive-pagination-text {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
}

.una-order-loading-screen {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 20px;
  padding: 25px;
  margin: 20px auto;
  max-width: 1400px;
}

.una-loading-content {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

.una-loading-icons {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.una-loading-icons svg {
  position: absolute;
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 10px currentColor);
}

.una-loading-icons .icon-primary {
  top: 0;
  left: 30px;
  color: var(--neon-cyan);
  animation: floatIcon 3s infinite;
}

.una-loading-icons .icon-secondary {
  bottom: 0;
  left: 0;
  color: var(--neon-green);
  animation: floatIcon 3s infinite 1s;
}

.una-loading-icons .icon-tertiary {
  bottom: 0;
  right: 0;
  color: var(--neon-magenta);
  animation: floatIcon 3s infinite 2s;
}

.una-loading-content h2 {
  color: var(--neon-cyan);
  text-shadow: var(--glow-cyan);
  font-size: 2em;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.una-loading-progress {
  width: 100%;
  height: 4px;
  background: rgba(0, 255, 157, 0.1);
  border-radius: 2px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;
}

.una-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  background: var(--neon-cyan);
  border-radius: 2px;
  animation: progress 3s infinite;
}

.una-progress-glow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 255, 255, 0.4), transparent);
  animation: glow 2s infinite;
}

.una-loading-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.una-metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background: rgba(13, 14, 26, 0.7);
  border-radius: 12px;
  border: 1px solid rgba(0, 255, 157, 0.1);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.una-metric-item svg {
  color: var(--neon-cyan);
  font-size: 1.2em;
}

.una-metric-item:nth-child(1) { animation-delay: 0.2s; }
.una-metric-item:nth-child(2) { animation-delay: 0.4s; }
.una-metric-item:nth-child(3) { animation-delay: 0.6s; }
.una-metric-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes floatIcon {
  0%, 100% { 
    transform: translateY(0) scale(1); 
    opacity: 1;
  }
  50% { 
    transform: translateY(-10px) scale(1.1); 
    opacity: 0.8;
  }
}

@keyframes progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

@keyframes glow {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}
